/**
 * Created by Lennaerd on 17-11-2015.
 *
 * JJ we maken een provider voor de api-service, zodat we zaken kunnen configureren in de configfase
 * dat gebeurt op het niveau van de provider ipv de gegenereerde service
 *
 * De api-service communiceert via zijn functie send(...) met het backend. Dat is spinjs2
 */
dynalearn.provider('api', function provideApi() {
    //de hele providerfunctie. Deze moet een provider returnen (een object, dat met New wordt gemaakt
    //met eventueel een publieke provider api (voor configuratie) en een $get functie die de service teruggeeft
    //we doen het op deze manier en niet met app.service, zodat we de provider beschikbaar hebben in de config
    //en dus apiProvider.backendConnect(...) kunnen aanroepen

    //private properties
    var debug = false;
    var quickLoad = false; //kan een object met modelid en startsim-flag bevatten, gezet via setDebug in localconfig. Deze wordt dan geladen als debug true is
    var flags = {}; //in config te zetten boolean flags, op te vragen als $rootscope.flag('...')
    return {
        /*************** configuratie ********************* /
         * dit deel is alleen beschikbaar als provider (apiProvider) in configuratie
         */

        setDebug: function (quickLoadModel, quickstartsim) {
            //ga in debugmodus: dat is vooral een flaggetje zetten, want loggen gaat via centrale sihwlog-config
            debug = true;
            if (quickLoadModel) {
                quickLoad = {model: quickLoadModel, startSim: !!quickstartsim}
            }
            return this;
        },

        /**
         * Zet een flag die straks als $scope.flag('...') beschikbaar is
         * @param flagname
         */
        flag: function (flagname) {
            console.log(`Run flag`, flagname);
            flags[flagname] = true;
            return this;
        },


        /*************** SERVICE **************************/
        //de service. Dit is de functie die ook als 2e arg in app.service gebruikt had kunnen worden
        //wij doen het zo, zodat we de provider hebben voor configuratie

        //injections minificationsave, dus met de naam ervoor
        //we injecten rootscope voor de string api_userstring

        $get: ['$q', '$rootScope', '$translate', '$timeout', 'sihwlog', 'spinJS2', function ($q, $rootScope, $translate, $timeout, sihwlog, spinJS2) {
            var $log = sihwlog.logLevel('debug');
            var isLoggedInPromise = null; //om dubbele calls bij opstarten te voorkomen
            $log.log('Create service apiService');

            //flags: te gebruiken in scope bijv apiProvider.flag('showextra'); ...  <div ng-show="flag('showextra')">
            $rootScope.flag = function (flag) {
                return (flag in flags);
            };
            $rootScope.apidebug = debug;

            return {
                unique: Date.now().toString(36) + '-' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36), //een uniqe id van deze instance, uniek genoeg in elk geval, om te gebruiken in interclient-messaging (bijv rond samenwerking)
                debug: debug,
                quickLoad: quickLoad,
                userdata: null,

                /**
                 * Is er een backendverbinding?
                 */
                connected: function () {
                    return spinJS2.connected();
                },

                getUrl: function () {
                    return spinJS2.getUrl();
                },

                /**
                 * login: aanmelden als user binnen een bepaald dynalearnproject
                 * @param data: bevat in elk geval username en password, en kan domein of domein en project bevatten
                 * @returns Promise van een resultaat object:
                 * loggedin: true / false - is men volledig ingelogd
                 * kiesdomein?: array. Als dit er is, dan moet er een domein gekozen worden
                 * kiesproject?: array. Als dit er is, dan moet er een project gekozen worden
                 * domein?: het domein op de inloggegevens slaan (door keuze of doordat er maar 1 is)
                 * project?: het project waarop nu is ingelogd
                 * userdata?: bij ingelogd ook de userdata
                 *
                 * Dit kan dus meertraps gedaan worden: eerst alleen user en wachtwoord. Dat kan voldoende zijn (bij 1 domein en 1 project)
                 * Als het niet voldoende is, komt er of een fixed domein terug, of een kiesdomein
                 * Als er een fixed domein terug komt (of login is aangeroepen met een domein) dan kan er een fixed project of kiesproject terugkomen
                 * Als alles wordt meegestuurd, is er nooit kiesdomein of kiesproject

                 Bij loggedin = true zijn domein, project en userdata altijd gegeven
                 */
                login: function (data) {
                    var self = this;

                    this.logout(); //alle data weg


                    return spinJS2.send('user', 'login', data).then(
                        function (res) {
                            //res heeft feitelijk de returndata die hierboven staat
                            //alleen ipv loggedin een authkey en userdata
                            //die authkey bevat een vers token en userdata
                            //daarmee loggen we opnieuw in op spinjs2

                            if (res.authkey) {
                                res.loggedin = true; //ook zometeen teruggeven
                                self.userdata = res.userdata;
                                //info voor in views:
                                spinJS2.setAuth(res.authkey);  //de teruggegeven key gebruiken we voortaan
                                delete res.authkey;
                            } else {
                                res.loggedin = false;
                            }
                            return res; //al dan niet ingelogd
                        })
                        .catch(function (err) {
                            $log.error("Inloggen gaf error", err);
                            self.logout(); //opschonen
                            return false;
                        });
                },

                /**
                 * Laat het backend checken of iemand binnen kan komen met een projectcode
                 * @param code
                 * @param email
                 * @param [bevestigingscode] {string} Als er al een bevestigingscode is, dan kan die mee
                 * @param [password] {string} Als er een bevestigingscode is, dan ook een password
                 * @param [displaynaam] {string}. Als er een bevestigingscode is, dan ook een displaynaam
                 * @returns Promise van het resutlaatobject
                 */
                aanmeldenCode: function (code, email, bevestigingscode, password, displaynaam) {
                    this.logout();
                    return spinJS2.send('user', 'aanmelden_code',
                        {
                            code: code,
                            email: email,
                            bevestigingscode: bevestigingscode,
                            password: password,
                            displaynaam: displaynaam,
                            lang: $translate.preferredLanguage()
                        }).then(
                        res => {
                            //is er ingelogd?
                            if (res && res.status === 'ingelogd') {
                                self.userdata = res.userdata;
                                //info voor in views:
                                spinJS2.setAuth(res.authkey);  //de teruggegeven key gebruiken we voortaan
                            }
                            return res;
                        }
                    )
                        .catch(err => {
                            $log.error(`Aanmelden code gaf error`, err);
                            this.logout();
                            return false;
                        })
                },

                /**
                 * Laat het backend inloggen via een lesdirectkey van material. Dit logt een gebruiker direct in een project in. als het goed is heeft material voorwerk gedaan en bestaat de gebruiker reeds
                 * @param key
                 */
                lesdirectlogin(key) {
                    this.logout();
                    return spinJS2.send('user', 'aanmelden_lesdirect', {
                        key: key
                    }).then(
                        res => {
                            //is er ingelogd?
                            if (res && res.status === 'ingelogd') {
                                self.userdata = res.userdata;
                                //info voor in views:
                                spinJS2.setAuth(res.authkey);  //de teruggegeven key gebruiken we voortaan
                            }
                            return res;
                        }
                    ); //doorvallen als het mislukt
                },

                /**
                 * Controleer of is ingelogd. Resolve met de userdata, of false als niet
                 * We checken dat 1 keer per sessie met het backend. Die ververst dan ook het token
                 * Nu alleen nog spinjs2
                 */
                isLoggedIn: function () {
                    if (isLoggedInPromise) {
                        //we zijn blijkbaar al bezig....
                        return isLoggedInPromise;
                    }

                    //is er wel een auth?
                    if (!spinJS2.hasAuth()) {
                        //direct returnen
                        this.logout();
                        return $q.resolve(false);
                    }

                    var self = this;

                    //om ingelogd te zijn moet er een spinjs1 en een spinjs2 token zijn
                    //maar voor een backend relogin is spinjs1 genoeg, want dan kunnen we door naar spinjs2
                    if (self.userdata) {
                        // we hebben al userdata, dus we geloven het. Al een keer gevraagd
                        return $q.resolve(self.userdata);
                    } else {
                        var deferred = $q.defer(); //zodat andere aanroepers dezelfde actie meekrijgen
                        isLoggedInPromise = deferred.promise;

                        spinJS2.send('user', 'relogin', {}).then(
                            function (res) {
                                //res bevat een vers token en userdata
                                //daarmee loggen we opnieuw in op spinjs2
                                self.userdata = res.userdata;
                                //info voor in views:
                                spinJS2.setAuth(res.authkey);  //de teruggegeven key gebruiken we voortaan
                                deferred.resolve(true); //positief resultaat
                                isLoggedInPromise = null; //nieuwe calls moeten opnieuw
                            })
                            .catch(function (err) {
                                //fout bij spinjs1 of spinjs2
                                $log.error(err);
                                self.logout(); //opschonen
                                deferred.resolve(false); //als in niet gelukt
                                isLoggedInPromise = null; //nieuwe calls moeten opnieuw
                            });

                        return isLoggedInPromise; //deze gaat wel resolven
                    }
                },

                /**
                 * Vraag het backend om nieuwe userdata en auth gegeven een projectswitch naar één van de andere projecten in de userdata
                 * @param project_id
                 */
                switchProject: function (project_id) {
                    return this.send('user', 'switchProject', {project_id: project_id}).then(res => {
                        if (!(res.authkey && res.userdata)) {
                            //mislukt
                            $log.error(`switchProject mislukt`, res);
                            return false;
                        }
                        //helemaal gezet
                        $log.debug(`switchProject toegestaan`, res);
                        this.userdata = res.userdata; //nieuwe userdata
                        spinJS2.setAuth(res.authkey); //nieuwe authkey
                        return true;
                    }).catch(e => {
                        $log.error(e);
                        return false; //mislukt
                    });
                },
                /**
                 * Logout
                 */
                logout: function () {
                    //verwijder onze informatie
                    spinJS2.deleteAuth(); //ook weg
                    this.userdata = null;
                    return true;
                },

                flag: $rootScope.flag, //functie ook beschikbaar als api.flag

                /**
                 * Vraag het be om een wachtwoordlink te maken en te sturen, voor wijzigen wachtwoord. BE geeft natuurlijk niet terug of het gegeven e-mailadres bekend is
                 * @param email
                 */
                stuurWachtwoordlink(email) {
                    return spinJS2.send('user', 'wwlink', {
                        email: email,
                        url: `${location.protocol}//${location.host}/#!/ww`,
                        lang: $translate.preferredLanguage()
                    });
                },

                /**
                 * Vraag het be of een link geldig is
                 * @param token
                 * @returns {*}
                 */
                isWachtwoordlink(token) {
                    return spinJS2.send('user', 'verifyWwlink', {
                        token: token
                    }).then(res => res.result);
                },

                /**
                 * Verzoek het be het wachtwoord te wijzigen
                 * @param token
                 * @param nieuwWachtwoord
                 */
                updateWachtwoord(token, nieuwWachtwoord) {
                    return spinJS2.send('user', 'updateWachtwoord',
                        {
                            token: token,
                            ww: nieuwWachtwoord
                        }).then(res => res.result);
                },


/////////////////////////// MODEL /////////////////////////////////////////////////////
                /**
                 * Sla het model in het backend
                 * @param {object} options Verplichte en optionele opslaanopties
                 * @returns {$q} promise die resolvet met false of een {id, titel} als het gelukt is
                 *
                 */
                saveModel: function (options) {
                    return $q(function (resolve, rej) {
                        spinJS2.send('model', 'save', options).then(
                            function (res) {
                                resolve(res);
                            },
                            function (error) {
                                $log.error('er is iets fout gegaan bij het opslaan van het model', error);
                                rej(error)
                            }
                        );
                    });
                },

                /**
                 * Stuur een image van het model (als base64-string) naar het backend
                 * @param {string} modelid
                 * @param {string} img Base64 (geen data-url)
                 * @param {number} modelversie Versie van het model waarop we saven
                 */
                saveModelImage: function (modelid, img, modelversie) {
                    return spinJS2.send('model', 'saveImage', {
                        id: modelid,
                        image: img,
                        versie: modelversie
                    });
                },

                /**
                 * Save normtellinggegevens
                 * @param modelid
                 * @param telling
                 * @param normtelling de tellers van de norm
                 */
                saveNormTelling: function (modelid, telling, normtelling) {
                    $log.debug(`saveNormtelling`, normtelling);
                    this.send('model', 'saveNormTelling', {
                        model: modelid,
                        telling: {model: telling, norm: normtelling}
                    });
                },

                /**
                 * Log een actie apart van het opslaan van een model. Handig voor ux-acties die ook bij volgende samenwerkers gelogd moeten worden, maar die geen actie bij de leider tot gevolg hebben
                 * @param {number} model_id
                 * @param {string} action
                 * @param {Object} [actionArgs] Argumenten bij de actie
                 * @param {string} [target] Route
                 * @param {string} [targettype]
                 * @param {string} [volger] clientid van de volger die de actie heeft gestart
                 * @param {string} [snapshot] mee te sturen snapshot
                 */
                //'model_id','ts','action','target','targettype','arguments'
                logAction(model_id, action, actionArgs, target, targettype, volger, snapshot) {
                    return this.send('model', 'logAction', {
                        model_id: model_id,
                        ts: Date.now(),
                        action: action,
                        arguments: actionArgs || {},
                        target: target,
                        targettype: targettype,
                        volger: volger,
                        snapshot: snapshot
                    });
                },

                /**
                 * Geef de volledige actionlog van dit model (of zijn master) terug.
                 * @param model modelid
                 * @param {boolean} [snapshots]
                 */
                fullActionLog: function (model, snapshots) {
                    return spinJS2.send("model", "actionlog", {
                        model: model,
                        snapshots: !!snapshots
                    }).then(
                        function (res) {
                            return res.log;
                        }
                    )
                },

                /**
                 * Geef het aantal acties in de actionlog van dit model (of zijn master) terug, op user en op targettype
                 * @param model modelid
                 */
                actionStats: function (model) {
                    return spinJS2.send("model", "actionlog", {
                        model: model,
                        stats: true
                    }).then(
                        function (res) {
                            return res.log;
                        }
                    )
                },


                /**
                 * haal alle modellen op voor de ingelogde gebruiker.
                 * @param {boolean} meekijken=false Open voor meekijken?
                 * @returns {*}
                 */
                getAllModels: function (meekijken) {
                    return $q(function (resolve, rej) {
                        spinJS2.send('model', meekijken ? 'meekijkmodellen' : 'all', {}).then(
                            function (res) {
                                resolve(res.models);
                            },
                            function (error) {
                                $log.error('er is iets fout gegaan bij het ophalen van alle modellen', error);
                                rej(error)
                            }
                        );
                    });
                },

                /**
                 * Haal alle beschikbare templates (en normmodellen) voor de ingelogde gebruiker
                 * Een template is een model binnen het project (van wie dan ook)
                 * die kan worden geladen als nieuw model
                 *
                 * @returns $q;
                 */
                getAllTemplates: function () {
                    return $q(function (resolve, rej) {
                        spinJS2.send('model', 'templates', {}).then(
                            function (res) {
                                if (!res.result) {
                                    resolve(false);
                                } else {
                                    resolve(res.models);
                                }
                            },
                            function (error) {
                                $log.error('Er is iets fout gegaan bij het ophalen van de templates', error);
                                rej(error);
                            }
                        )
                    });
                },

                /**
                 * een model ophalen zodat we het model kunnen inladen
                 * @param modelid
                 * @param {boolean} [isAdminKey] true als de gegeven id een versleutelde toegangskey is
                 *
                 * over naar Spinjs2
                 */
                getModelById: function (modelid, isAdminKey) {
                    return $q(function (resolve, rej) {
                        spinJS2.send('model', 'get', {
                            id: modelid,
                            adminKey: isAdminKey
                        }).then(
                            function (res) {
                                if (!res) {
                                    resolve(false);
                                } else {
                                    resolve(res);
                                }
                            },
                            function (error) {
                                $log.error('er is iets fout gegaan bij het ophalen van alle modellen', error);
                                rej(error)
                            }
                        );
                    });
                },

                /**
                 * Verwijder het model
                 * @param modelid
                 * @returns {$q} met boolean succes
                 */
                deleteModel: function (modelid) {
                    return spinJS2.send('model', 'del', {id: modelid}).then(function (res) {
                        return res.result;
                    });
                },

                /**
                 * Haal een volledige simulatie op
                 * @param modelid
                 * @param {boolean} force=false Forceer herbouw simulatie door backend
                 * @returns {Promise} Let op, met {result: bool, simulatie?: obj, melding?: string}. Melding bij problemen:
                 * SIMULATE_ERROR, SIMULATE_BUSY, SIMULATE_TIMEOUT -->timeout is relevante feedback want waarschijnlijk complex model
                 */
                simulate: function (modelid, force) {
                    $log.debug(`Verzoek om simulatie ${force ? 'geforceerd' : ''}`);
                    return spinJS2.send('simulate', 'start', {id: modelid, force: !!force}).then(function (res) {
                            if (res.simulatie) {
                                //is json, obj van maken
                                try {
                                    res.simulatie = JSON.parse(res.simulatie);

                                } catch (e) {
                                    $log.error("catch", e);

                                    return {
                                        resultaat: false,
                                        melding: "Fout bij omzetten simulatiedata"
                                    }
                                }
                                return res;
                            } else {
                                return {resultaat: false, melding: "Geen simulatie"}
                            }
                        },
                        function (error) {
                            $log.error("fout bij simuleren", error);
                            return {
                                resultaat: false,
                                melding: 'Fout: ' + error
                            };
                        });
                },

                /**
                 * Sla een js object onder een bepaalde key op voor de ingelogde gebruiker
                 * @param {String} key
                 * @param {Object} jsobject
                 * @returns $q Promise die resolvt als ok, anders reject
                 */
                saveUserData: function (key, jsobject) {
                    return spinJS2.send('user', 'saveData', {
                        key: key,
                        data: jsobject
                    }).then(
                        function (res) {
                            return res.succes ?
                                true : $q.reject(false);
                        }
                    )
                },

                /**
                 * Haal de jsobject blob onder een bepaalde key op voor de ingelogde gebruiker.
                 * @param {String} key
                 * @returns $q Promise die resolvt met de data of false als de data er niet is
                 */
                haalUserData: function (key) {
                    return spinJS2.send('user', 'haalData', {key: key}).then(
                        function (res) {
                            return res.data;
                        }
                    )
                },

                ////////////// SAMENWERKEN //////////////////////
                /**
                 * Haal de samenwerkstate van het gegeven model op, spinjs2. Skipt meekijken
                 * @param modelid
                 */
                samenwerkState: function (modelid) {
                    return spinJS2.send('model', 'samenwerkState', {
                        model: modelid
                    });
                },

                setSamenwerkmaster: function (modelid) {
                    return spinJS2.send('model', 'setSamenwerkmaster', {
                        model: modelid
                    });
                },

                /**
                 * Vraag aan het be om een nieuw model te maken voor de huidige user die samenwerk-slave is van het mastermodel met de opgegeven code
                 * @param mastercode
                 */
                createSamenwerkslave: function (mastercode) {
                    return spinJS2.send('model', 'createSamenwerkslave', {mastercode: mastercode});
                    //geeft een .master en .slave terug
                },

                /**
                 * laat het be de samenwerking ontkoppelen, voor master of slave. Een ander model wordt dan master
                 * @param modelid
                 */
                ontkoppelSamenwerking: function (modelid) {
                    return spinJS2.send('model', 'ontkoppelSamenwerking', {model: modelid});
                    //geeft niets nuttigs terug
                },

                /**
                 * Regel het meekijken met een modelid. Uiteindelijk wordt dit geïmplementeerd als samenwerken, met wat extra zaken
                 * @param modelid
                 */
                gaMeekijken: function (modelid) {
                    return spinJS2.send('model', 'gaMeekijken', {model: modelid});
                },

                /**
                 * Vraag of dit model is geopend voor meekijken (wil niet zeggen dat er NU iemand meekijkt, maar we proberen het goed bij te houden)
                 * @param modelid
                 * @returns {*}
                 */
                checkMeekijken: function (modelid) {
                    return spinJS2.send('model', 'checkMeekijken', {model: modelid});
                },

                /**
                 * Registreer of deregistreer (modelid = null) samenwerking bij het backend. Dit zorgt voor notificaties als een ander het model wijzigt. Het backend kan dit niet automatisch ivm eventueel wegvallen van de verbinding: frontend moet dat aanroepen bij wijzigen model, of op spinjs2.connect (modelservice).
                 * Return is een promise met {werkmodus: ..} de nieuwe werkmodus voor het model (als modelid niet null is)
                 * @param modelid
                 * @param [meekijkid] id van het meekijkmodel dat geopend is
                 */
                registreerSamenwerken: function (modelid, meekijkid) {
                    //alleen als we zijn ingelogd
                    return this.isLoggedIn().then(ingelogd => {
                        if (ingelogd) {
                            return spinJS2.send('model', 'registreerSamenwerken', {
                                model: modelid, //kan null zijn voor 'deregistreer'
                                meekijkid: meekijkid //optioneel als we een meekijkmodel openhouden
                            });
                        } else {
                            return false;
                        }
                    });
                },

                /**
                 * Laat api een samenwerkbericht sturen. Leiders en volgers sturen acties die moeten of zijn uitgevoerd.
                 * Als een volger dat stuurt, gaat het naar de leider, die hem mogelijk uitvoert
                 * Als een leider het stuurt, is het uitgevoerd en moeten de volgers meedoen
                 * @param brontype 'volg' of 'leid'
                 * @param {string | boolean} changeId Id die deze change representeert als hij van een leider afkomt. Voor latere communicatie over doorgevoerde changes
                 * @param lokaalmodel id van het slavemodel (of mastermodel) waarover we het hebben
                 * @param mastermodel  id van het mastermodel waarover we het hebben
                 * @param {string|undefined} originele_afzender
                 * @param {object[]} acties Lijst met acties met in elk geval de actienaam in prop actie, en extra nodige argumenten
                 * @param {object} [extra] eventuele extra data
                 */
                samenwerkbericht: function (brontype, changeId, lokaalmodel, mastermodel, originele_afzender, acties, extra) {
                    return spinJS2.send('model', 'samenwerkbericht', {
                        brontype: brontype,
                        changeId: changeId,
                        lokaalmodel: lokaalmodel,
                        mastermodel: mastermodel,
                        originele_afzender: originele_afzender,
                        acties: acties,
                        extra: extra
                    });
                },

                /**
                 * Communicatie tussen de modelservices van samenwerkende clients over de undostatus van de leider
                 * @param canundo
                 * @param canredo
                 */
                samenwerkUndostatus: function (canundo, canredo) {
                    //simpele statusupdate
                    return spinJS2.send('model', 'samenwerkundostatus', {
                        canUndo: canundo,
                        canRedo: canredo
                    });
                },


                ///chat
                /**
                 * Wrapper om simpele backendcall, we doen er verder weinig mee
                 * @param tekst
                 * @param model
                 */
                chatSend: function (tekst, model) {
                    if (model.id) {
                        return this.send('model', 'chat', {
                            model: model.notifyId, //altijd het hoofdmodel
                            tekst: tekst
                        });
                    }
                },
                /**
                 * Backendcall voor markeren als gelezen van bepaalde chats
                 * @param {number[]} ids
                 */
                chatGelezen: function (ids) {
                    return this.send('model', 'chatGelezen', {ids: ids});
                },

                /**
                 * Stuur bericht voor het synchroniseren van de simulatie. Transparante call, verder ingevuld door de fe-functionaliteit in cytocanvas.controller
                 * @param {Object} bericht
                 */
                simulatiebericht: function (bericht) {
                    return spinJS2.send('model', 'simulatiebericht', {
                        bericht: bericht
                    });
                },

                /**
                 * Ruwe proxy richting spinJS2
                 * @param controller
                 * @param action
                 * @param [data]
                 * @returns {*}
                 */
                send: function (controller, action, data) {
                    return spinJS2.send(controller, action, data);
                },

                //toast
                /**
                 * Simpele toast: zet een text in de rootscope en toont de toast, heel simpel. Zie canvas.html en less
                 * @param translatekey
                 * @param toastclass
                 */
                toast: function (translatekey, toastclass) {
                    $rootScope.toastdata = {
                        translatekey: translatekey,
                        toastclass: toastclass
                    };
                    $timeout(_ => {
                        $rootScope.toastdata = null
                    }, 15000);
                },

                /**
                 * Make sure change detection runs after async code
                 */
                updateChanges() {
                    $timeout(function () {
                    }, 0);
                }
            }
        }
        ]
    }
});
