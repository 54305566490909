/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 16-8-2016
 * Time: 13:13
 *
 * Aparte service voor het beheer van het model, inclusief opslaan, undo, log
 * via $scope.model = (deze service) kan er veel informatie worden opgehaald
 *
 * Beheer data: vóór elke logische wijziging model.setUndoPoint() aanroepen, ná elke wijziging model.save
 *
 * We detachen de objecten volledig van de objecten in een canvas, want het bleek dat undo / redo bijv invloed had op bestaande objecten (object identity op position-object)
 *
 * Broadcast events over het model naar alle scopes (dus luister op lokale scope):
 * - model.changed: vuurt als het model helemaal nieuw moet worden ingeladen
 * - model.contentChanged: vuurt als er een wijziging is binnen het model: element erbij of eraf (alleen als dit gelogd wordt via logAction)
 * - model.propsChanged: vuurt als er properties gewijzgd (kunnen) zijn binnen hetzelfde model
 * - model.werkmodusChanged: vuurt als we een nieuwe werkmodus hebben gekregen
 * - model.norminterpretatieChanged: vuurt als de norminterpretatie wijzigt
 *
 * //we zijn bezig de termen slave en master kwijt te raken ivm historische connotatie. We gebruiken nu sub en primary. In het datamodel kan het helaas nog niet
 */

dynalearn.factory('model', ['$rootScope', '$translate', '$q', '$timeout', '$uibModal', 'sihwlog', 'api', 'elementService', 'erroralert',
    function ($rootScope, $translate, $q, $timeout, $uibModal, sihwlog, api, elementService, erroralert) {
        const log = sihwlog.logLevel('debug');

        const normnotationversion = 4;

        //var model --> de this van de service, wordt hieronder gezet
        let
            _data = {}, //de opgeslagen of ingelezen modeldata volgens het standaardformaat, gedeepcloned
            undoBuffer = {
                undoStack: [], //opgeslagen relevante tussenstappen
                redoStack: [] //na undo ontstaat redo
            },
            maxUndo = 200, //undostappen die we bijhouden
            saveDelay = 1500, //hoelang voordat we gaan opslaan?
            actionbuffer = [], //achterstallige actionbuffer voor loggen
            modelSavePlanner, //promise voor save
            generation = 1, //welke modelgeneration zijn we mee bezig (voor async handling)
            saveGeneration = 0, //de generatie waarvoor het saven bezg is
            normmatchgeneration = 0, //generatie normmatch-runner
            afterSavePromise = null, //een promise die we zetten bij saven en die resolvt als we klaar zijn (gelukt of niet). Zie onNotSaving()
            /**
             * De worker die interpretaties op normen maakt.
             * @type {Worker}
             */
            normMatchWorker = null;

        //vang de reconnect op van spinjs
        $rootScope.$on('SpinJS2.reconnect', async function () {
            log.debug(`Reconnect`);
        });

        //backend vraag expliciet om herregistratie.
        //dat gebeurt na een connect, want soms heeft de client niet door
        //dat er een verbindingsprobleem is geweest.
        //dit is dus de plek voor herrgisteren van samenwerken

        $rootScope.$on('api.notify.registratieVerzoek', async function () {
            if (model) {
                log.debug(`Backend vraagt om registratie`);
                await model.registreerSamenwerken(); //opnieuw doen

                //dit is ook een goed moment om achterstallig save-werk te doen
                //TODO: beter van niet? Of in elk geval checken van versie?
                if (model.isSaved()) {
                    log.debug(`Opslaan model na herregistratie`);
                    //en opslaan, zonder nieuw plaatje
                    saveModel();
                }
            }
        });


        $rootScope.$on('api.notify.clientOnline', function () {
            //als we de leider zijn, moeten we onze undostatus even melden
            if (model.werkmodus === 'leid') {
                model.verzendUndostatus(); //dat moet de rest dan weten
                //en we sturen de norminterpretaties opnieuw
                //naar de volgers
                model.stuurNorm();
            }
        });

        //clientnamen worden doorgegeven bij samenwerking
        $rootScope.$on('api.notify.clientnames', (_e, data) => {
            if (data.model === model.notifyId) {
                model.clientnames = data.names.filter(n => n !== api.userdata.displayname).join(', ');
            } else {
                //reset even
                model.clientnames = "";
            }
        });

        //En soms krijgen we een nieuwe samenwerkmodus door
        $rootScope.$on('api.notify.wijzigSamenwerkmodus', function (_e, werkmodusData) {
            if (model && werkmodusData.model === model.notifyId && werkmodusData.werkmodus !== model.werkmodus) {
                let hadNormleiding = model.werkmodus !== 'volg'; //we hadden al een eventuele normmatch zelf
                //het gaat over ons huidige model
                model.werkmodus = werkmodusData.werkmodus;
                if (model.werkmodus === 'leid') {
                    model.verzendUndostatus(); //dat moet de rest dan weten
                }
                $rootScope.$broadcast('model.werkmodusChanged'); //laat het intern ook weten
                //normmatch opnieuw, eventueel juist killen
                //we krijgen een nieuw foutmodel wel door
                if (model.werkmodus !== 'volg') {
                    //we moeten nu dus gaan maken
                    if (hadNormleiding) {
                        //we hebben hem al
                        log.debug(`Normmatch al bekend -> sturen`);
                        model.stuurNorm();
                    } else {
                        //we moeten hem maken
                        log.debug(`Normmatch nog niet bij ons -> maken`);
                        model.maakNormmatch();
                    }
                }
            }
        });
        //en soms wordt de hele samenwerking gereset, als iemand ontkoppelt bijvoorbeeld
        $rootScope.$on('api.notify.resetSamenwerking', function () {
            //reloaden
            if (model) {
                model.reload();
            }
        });

        /**
         * leider meldt andere undostatus. Die slaan we op.
         */
        $rootScope.$on('api.notify.undostatus', function (_e, undostatus) {
            //we hoeven niet te checken of we leider zijn, gewoon opslaan
            model.leiderCanUndo = undostatus.canUndo;
            model.leiderCanRedo = undostatus.canRedo;
        });


        /*************** Helpers *************************/
        /**
         * deepclone een object
         * @param obj
         */
        function clone(obj) {
            return angular.merge({}, obj);
        }

        /**
         * Voeg onze vaste metadata toe aan stringified modeldata
         * @param data
         */
        function addMeta(data) {
            data.meta = {
                id: model.id,
                name: model.titel,
                norm: model.norm,
                notationversion: 1 //welke notatie gebruiken we in dit model
            };
        }

        /**
         * Sla onze _data op
         * levert een promise op die resolvet met true/false of het gelukt is, geen reject!
         * LET OP: slaat ook op als het model niet eerder is opgeslagen, (id==null). Dat moet dus elders gecheckt worden
         * Zet ook de promise voor onNotSaving
         * @param {function} [imgGenerator] Generator voor een base64 string (geen url) van een jpg die we bij succes opslaan bij het model (na het gewone opslaan)
         *
         */
        function saveModel(imgGenerator) {
            if (afterSavePromise) {
                log.debug(`saveModel uitgesteld, want nog bezig`);
                //al bezig, want promise wordt genulled als we klaar zijn
                // even uitstellen
                return model.onNotSaving().then(function () {
                    return saveModel(imgGenerator);
                });
            }

            return $q(function (resolve) {
                if (model.werkmodus === 'volg') {
                    resolve(false);
                    return;
                }
                if (!_data) {
                    resolve(false);
                    return;
                }
                //zijn we al aan het opslaan?
                if (saveGeneration === generation) {
                    resolve(false);
                    return;
                }
                afterSavePromise = $q.defer(); //om hieronder te resolven en te gebruiken via model.onNotSaving
                addMeta(_data); //onze eigen metadata
                let json;
                try {
                    json = JSON.stringify(_data);
                } catch (e) {
                    log.error(e);
                    resolve(false);
                    return;
                }

                //we moeten opslaan
                var saving = saveGeneration = ++generation; //deze generatie slaan we op. Even in een scopevar zodat hij niet wijzigt (saveGeneration is moduleglobal)
                var opslaan = {
                    id: model.id,
                    titel: model.titel,
                    json: json,
                    modelleerniveau: model.modelleerniveau,
                    simulatiepreferentie: model.simulatiepreferentie,
                    fastest_path: model.fastest_path,
                    actionbuffer: actionbuffer,
                    uniqueTitel: ((!model.id) && (model.titel === model.defaulttitle)) //als niet eerder opgeslagen en standaardnaam: maak hem uniek
                };

                opslaan.samenwerking = {
                    vanVersie: model.versie
                };

                if (model.werkmodus === 'leid') {
                    opslaan.samenwerking.changes = model.changes;
                }
                model.changes = []; //meteen legen, want de rest zit niet in deze versie

                api.saveModel(opslaan).then(
                    function (savedInfo) {
                        log.debug(`*** Opslaan gelukt`);
                        //res is false of bevat id
                        saveGeneration = 0; //klaar met saven
                        let oldAftersavePromise = afterSavePromise;
                        afterSavePromise = null; //dus weg
                        if (generation !== saving) {
                            //ondertussen een ander model...
                            //we resolven niet eens
                            resolve('Oude generatie');
                            oldAftersavePromise.resolve(true); //altijd resolven
                            return;
                        }
                        if (!savedInfo) {
                            resolve(false);
                        } else {
                            //gelukt
                            let oldid = model.id;
                            model.id = savedInfo.id; //was misschien al
                            model.titel = savedInfo.titel || model.titel; //kan gewijzigd zijn als we een unieke wilden
                            model.userprefix = savedInfo.userprefix || model.userprefix;
                            model.versie = savedInfo.versie; //onze huidige opslagversie
                            addMeta(_data); //zet de metadata goed
                            actionbuffer = []; //die kan weer leeg
                            if (!oldid) {
                                //eerste keer opslaan
                                //registreren van het model ("samenwerking") moet hier wel, omdat dit ook voor meekijken nodig is
                                if (!model.notifyId) {
                                    model.notifyId = model.id; //zelfde
                                }
                                model.registreerSamenwerken();
                                //en de normtelling
                                model.stuurNormTelling();
                            }
                            model.samenwerkfeedback = parseSamenwerkfeedback(savedInfo.samenwerkfeedback) || model.samenwerkfeedback; //experimenteel

                            //als er een imageGenerator is en die doet het, sturen we een plaatje
                            if (imgGenerator) {
                                var img = null;
                                try {
                                    img = imgGenerator();
                                } catch (_e) {
                                    log.error('Model: imagegenerator throw');
                                    log.error(_e);
                                }
                                if (img) {
                                    api.saveModelImage(model.id, img, model.versie); //met opslagversie
                                }
                            }
                            oldAftersavePromise.resolve(true); //altijd resolven
                            resolve(true);
                        }
                    },
                    async function (err) {
                        log.debug(`*** Opslaan reject`);
                        if (api.connected()) {
                            await erroralert(err);
                        } else {
                            log.error("Save mislukt want geen verbinding - geen foutmelding");
                        }
                        saveGeneration = 0; //klaar met saven, ookal is het mislukt
                        var oldAftersavePromise = afterSavePromise;
                        afterSavePromise = null; //dus weg
                        oldAftersavePromise.resolve(true); //toch doorgaan
                        resolve(false);
                    });
            });
        }

        /**
         * Schrijf van achteren gekomen samenwerkfeedback om naar iets nuttigs
         * @param swfb
         */
        function parseSamenwerkfeedback(swfb) {
            if ((!swfb) || (model.samenwerking == 'single') || (!api.flag('samenwerkfeedback'))) {
                return false; //verbooleanen
            }

            //we werken met ik vs de rest. Alles in percentage
            var feedback = {
                totaal: {
                    ik: 0,
                    rest: 0
                },
                entity: {
                    ik: 0,
                    rest: 0
                },
                quantity: {
                    ik: 0,
                    rest: 0
                },
                rest: {
                    ik: 0,
                    rest: 0
                }
            };

            swfb.forEach(function (stat) {
                var key = stat.user_id == api.userdata.user ? "ik" : "rest";
                feedback.totaal[key] += stat.count;

                //de rest parsen
                var typekey = "rest"; //standaard
                if (stat.targettype) {
                    switch (stat.targettype) {
                        case 'entity':
                        case 'configuration':
                            typekey = 'entity'
                            break;
                        case 'quantity':
                            typekey = 'quantity';
                            break;
                    }
                }
                if (typekey in feedback) {
                    feedback[typekey][key] += stat.count; //erbij
                }
            });

            //okee, nu nog omzetten naar percentages
            Object.keys(feedback).forEach(function (typekey) {
                var som = feedback[typekey].ik + feedback[typekey].rest;
                feedback[typekey].rest = som > 0 ? Math.round(feedback[typekey].rest / som * 100) : 0;
                //ik heb de rest. Betere afronding

                feedback[typekey].ik = 100 - feedback[typekey].rest;
                feedback[typekey].balans = Math.abs(feedback[typekey].ik - feedback[typekey].rest);
            });

            return feedback;

        }

        /**
         * Broadcast over alle scopes dat er nieuwe modeldata is geladen.
         * @param otherModel zet het otherargument
         * Event: model.changed,
         * args:
         *  other (boolean) Is er een heel nieuw model geladen, of een variant op hetzelfde model (undo/redo)
         *  data: de huidige _data
         *
         */
        function broadCastChange(otherModel) {
            //clonen, zodat er geen koppeling blijft in data en positieobjecten
            $rootScope.$broadcast('model.changed', {
                other: otherModel,
                data: clone(_data)
            });
        }

        //korte vertaling
        function __() {
            return $translate.instant.apply($translate, arguments);
        }

        /*************** EXPORT *************************/
            //var model wordt ook gewoon gebruikt in de private functies hierboven
        const model = {
                id: null,
                notifyId: null, //id die we volgen voor wijzigingen (samenwerken)
                defaulttitle: __('NEWMODEL'),
                titel: __('NEWMODEL'), //titel voor weergave en als modelnaam in backend
                norm: false,
                norminterpretaties: [], //een x aantal interpretaties met foutmodellen
                normmatch: null, //interpretatie van het werkmodel op het normmodel, met mogelijke fouten
                foutniveau: 0, //welk van de 4 foutniveaus wordt getoond? (0 = laat niets zien, anders 1,2,3)
                telling: {}, //als we met een normmodel werken, tellen we de elementen (steeds opnieuw)
                modelleerniveau: 0,
                effectief_modelleerniveau: 0, //uitgerekend obv instellingen van het project
                simulatiepreferentie: 0, //is vooral bedoeld voor de simulator, we hoeven hier niet de effectieve pref uit te rekenen
                fastest_path: null, //simulatie: moet fastest_path gebruikt worden (alleen als project overschrijving toestaat)
                samenwerking: 'single', //samenwerkingstatus
                werkmodus: 'lokaal', //hoe verwerken we lokale en externe wijzigingen? 'lokaal','volg','leid'
                clientnames: '', //string van doorgeven namen van gebruikers die het model ook bekijken
                samenwerkfeedback: false, //feedbackinformatie,experimenteel,
                changes: [], //changes die wel als leider hebben bericht sinds de vorige save, of als volger hebben ontvangen sinds de vorige modelchanged
                versie: 0, //de opgeslagen versie
                leiderCanUndo: false,
                leiderCanRedo: false,
                _fullReadonlyStack: [], //hier pushen en poppen we readonly-statussen in, de getter fullreadonly geeft true als de stack niet leeg is
             /*   fullReadonly: false, //zijn we vol readonly, dus niks saven of wijzigen of doen. Kan gewoon gezet worden, bijv in replay modus*/

                /*************** Methoden *****************************/
                /**
                 * Zijn we vol readonly, dus niks saven of wijzigen of doen. Wordt geset via de setter, die feitelijk een stack pusht of popt, bijv in replay modus
                 * Geeft ook fullreadonly als we disconnected zijn
                 * @returns boolean
                 */
                get fullReadonly() {
                  return this._fullReadonlyStack.length > 0 || (! api.connected()); //nog een readonly op de stack
                },
                /**
                 * Zet de fullreadonly status, door op onze stack te poppen of te pushen, kan dus meerdere keren gezet worden, er moet net zo vaak ge-unset worden
                 * @param {boolean} fullReadonly
                 */
                set fullReadonly(fullReadonly) {
                  if (fullReadonly)
                  {
                      this._fullReadonlyStack.push(true);
                  }
                  else {
                      this._fullReadonlyStack.pop();
                  }
                },
                /**
                 * Reset wat basisdata voor de verschillende new(..) varianten
                 * @private
                 * @param {boolean} [keepUndobuffer]
                 */
                _reset: function (keepUndobuffer) {
                    generation++; //oude saves zijn ongeldig op dit model
                    normmatchgeneration++; //oude normmatches zijn ongeldig
                    this.titel = this.defaulttitle;
                    this.userprefix = "";
                    this.modelleerniveau = 0; //standaardniveau vh project
                    this.bepaalModelleerNiveau();
                    this.simulatiepreferentie = 0; //standaard vh project
                    this.fastest_path = null; //standaard vh project
                    this.samenwerking = 'single';
                    this.werkmodus = 'lokaal'; //hoe werken we - geen broadcast
                    this.clientnames = '';
                    this.fullReadonly = false; //nu niet meer
                    this.samenwerkfeedback = false;
                    this.changes = [];
                    this.chat = []; //chatobjecten
                    this.leiderCanUndo = this.leiderCanRedo = false; //we weten nog niet of de leider can redo/undo
                    this.id = this.notifyId = null; //nog niet opgeslagen, gaat dan ook niet vanzelf
                    this.norm = false;
                    this.norminterpretaties = [];
                    this.normmatch = null;
                    this.foutniveau = 0;

                    this.versie = 0;
                    //telling tbv de norm in dit model
                    //elementypes
                    this.telling = {};
                    //namen
                    this.naamtelling = {};
                    //standaarddingen
                    let newdata = {
                        cy: {},
                        nodes: {}, //op id
                        edges: [], //gewoon erin
                        model: {  //logischer model, bevat de data
                            elements: {}, //elementen op id (de data)
                            types: {} //per type een array met ids
                        }
                    };
                    addMeta(newdata);
                    _data = newdata;
                    actionbuffer = []; //lege actionbuffer
                    if (!keepUndobuffer) {
                        //reinit undo
                        undoBuffer = {
                            undoStack: [], //opgeslagen relevante tussenstappen
                            redoStack: [] //na undo ontstaat redo
                        };
                    }
                },
                /**
                 * Maak een leeg, nieuw model
                 * @param {boolean} [silent] geen broadcast
                 * @returns {boolean}
                 */
                new: function (silent) {
                    //init model
                    this._reset();

                    return this.registreerSamenwerken(true) //deregistreer
                        .then(_ => {
                            if (!silent) {
                                broadCastChange(true); //het is een ander model
                            }
                            return true; //altijd
                        });
                },

                /**
                 * Maak een leeg, nieuw model op basis van een template
                 * @param {string} templateid id van model dat als template dient, moet geladen kunnen worden
                 * @param {boolean} [isAdminKey] true als de id een adminkey is
                 * @returns {Promise.boolean}
                 */
                newUitTemplate: function (templateid, isAdminKey) {
                    this._reset();
                    var $this = this;
                    return api.getModelById(templateid, isAdminKey).then(function (loaddata) {
                        if (loaddata) {
                            let modeldata;
                            try {
                                modeldata = JSON.parse(loaddata.json);
                            } catch (e) {
                                log.error(e);
                                return false;
                            }
                            $this.modelleerniveau = loaddata.modelleerniveau; //deze wel als in template, maar modelbouwcode bepaalt via project-props of het mag
                            $this.bepaalModelleerNiveau();
                            $this.simulatiepreferentie = loaddata.simulatiepreferentie; //en ook via projectprops goed zetten
                            $this.fastest_path = api.userdata.projectdata.fastest_path_overschrijfbaar ? loaddata.fastest_path : null;
                            //een sjabloon kan ook een norm bevatten
                            $this.norm = modeldata.meta && modeldata.meta.norm || false;
                            addMeta(modeldata);
                            _data = modeldata; //clonen niet nodig, want uit json

                            //normtelling
                            $this.loadFixes();
                            return $this.registreerSamenwerken(true).then(() => {
                                $this.updateTelling();
                                broadCastChange(true);
                                return true;
                            });
                        } else {
                            return false;
                        }
                    });
                },

                /**
                 * Maak een leeg, nieuw model met het gegeven template als normmodel
                 * @param {string} [templateid] id van model dat als norm dient, moet geladen kunnen worden als template
                 * @returns {Promise.Boolean}
                 */
                newMetNormmodel: function (templateid) {
                    this._reset();
                    const $this = this;
                    return api.getModelById(templateid).then(function (loaddata) {
                        if (loaddata) {
                            let modeldata;
                            try {
                                modeldata = JSON.parse(loaddata.json);
                            } catch (e) {
                                log.error(e);
                                return false;
                            }
                            //meta als nieuw model
                            $this.modelleerniveau = loaddata.modelleerniveau; //deze wel als in normmodel, maar modelbouwcode bepaalt via project-props of het mag
                            $this.bepaalModelleerNiveau();
                            $this.simulatiepreferentie = loaddata.simulatiepreferentie; //en ook via projectprops goed zetten
                            $this.fastest_path = api.userdata.projectdata.fastest_path_overschrijfbaar ? loaddata.fastest_path : null;
//standaarddingen
                            //NORMMODEL
                            //we gaan allerlei normdata opslaan
                            //node-info zoals posities toevoegen
                            for (let normnode of Object.keys(modeldata.model?.elements || {})) {
                                modeldata.model.elements[normnode].position = modeldata.nodes[normnode]?.position;
                            }
                            let from = {
                                id: templateid,
                                titel: loaddata.titel, //ter info
                                content: modeldata.model
                            };

                            $this.norm = {
                                notationversion: normnotationversion, //op welke manier noteren we dit?
                                from: from,
                                namen: {},
                                teller: {
                                    entity: 0,
                                    agent: 0,
                                    assumption: 0,
                                    configuration: 0,
                                    attribute: 0,
                                    quantity: 0,
                                    quantity_space_point: 0,
                                    quantity_space_interval: 0,
                                    // value: 0,
                                    ineq: 0,
                                    correspondence: 0,
                                    proportionality: 0,
                                    influence: 0,
                                    causal: 0, //dubbele telling van de bovenstaande 2 samen - afhankelijk van modelleerniveau welke wordt afgebeeld
                                    calc: 0,
                                    // q_exo: 0,
                                    // quantity_allvalues: 0,
                                }
                            };

                            let tellers = Object.keys($this.norm.teller);
                            /*
                                                        //tijdelijk: alles minimaal 1, voor bouwen
                                                        log.warn("Let op: normmodel heeft elle elementen op 1, voor bouwen");
                                                        for(let t of tellers)
                                                        {
                                                            $this.norm.teller[t]++;
                                                        }*/

                            Object.keys(modeldata.model.elements).forEach(function (elid) {
                                let el = modeldata.model.elements[elid];
                                let eldef = elementService.getDefinition(el.type);
                                if (eldef.hasname) {
                                    //heeft officieel een naam
                                    //ook als dit geen geteld element is werken we met de naam

                                    //speciaal: punt zero: tellen we als aparte naam
                                    let naam = el.nodename ?? '';
                                    let m = naam.match(/^\s*(.*?)\s*$/,);
                                    if (m) {
                                        naam = m[1]; //zonder de eerste
                                    }
                                    naam = naam.replace(/\s{2,}/g, ' '); //max 1 spatie
                                    if (el.type === 'quantity_space_point' && el.isZero) {
                                        log.debug(`QSPUNT`, el);
                                        naam = "___qszero"; //speciale tag, wordt vertaald waar dat nodig is
                                    }

                                    if (!(naam in $this.norm.namen)) {
                                        $this.norm.namen[naam] = 0;
                                    }
                                    $this.norm.namen[naam]++;
                                }
                                tellers.forEach(function (t) {
                                    if (elementService.isSubtypeOf(el.type, t)) {
                                        //log.debug(`Norm: Element van type ${el.type} geteld als ${t}`);
                                        $this.norm.teller[t]++;
                                    }
                                });
                            });

                            return $this.registreerSamenwerken(true).then(() => {
                                $this.updateTelling();
                                broadCastChange(true);
                                return true;
                            });
                        } else {
                            return false;
                        }
                    });
                },

                /**
                 * Geef aan of we met een opgeslagen model werken
                 * @return {boolean}
                 */
                isSaved: function () {
                    return !!this.id;
                },

                /**
                 * Geeft een promise die resolvt als we niet aan het opslaan zijn (dus niet wachten op be response)
                 * @returns {$q.deferred.promise}
                 */
                onNotSaving: function () {
                    return afterSavePromise ? afterSavePromise.promise : $q.resolve(true);
                },

                /**
                 * Laad een nieuw model in via de api
                 * @param modelid
                 * @returns {$q} met true of false of een reject
                 */
                load: function (modelid) {
                    //ophalen maar
                    this._reset();
                    const $this = this;
                    return api.getModelById(modelid).then(function (loaddata) {
                        if (loaddata) {
                            let modeldata;
                            try {
                                modeldata = JSON.parse(loaddata.json);
                            } catch (e) {
                                log.error(e);
                                return false;
                            }
                            //we zetten de gegevens goed
                            $this.id = loaddata.id;
                            $this.titel = loaddata.titel;
                            $this.userprefix = loaddata.userprefix; //een username als het model van een ander is
                            $this.modelleerniveau = loaddata.modelleerniveau; //modelbouwcode kijkt wel of dit mag als niet 0
                            $this.bepaalModelleerNiveau();
                            $this.simulatiepreferentie = loaddata.simulatiepreferentie;
                            $this.fastest_path = api.userdata.projectdata.fastest_path_overschrijfbaar ? loaddata.fastest_path : null;
                            $this.norm = modeldata.meta && modeldata.meta.norm || false;
                            $this.samenwerking = loaddata.samenwerking;
                            $this.meekijkmodel = loaddata.meekijkmodel;
                            $this.versie = loaddata.versie;
                            //chat wordt ook gewoon geladen
                            $this.chat = loaddata.chat; //klaar
                            addMeta(modeldata);
                            _data = modeldata; //clonen niet nodig, want uit json
                            //normtelling
                            $this.loadFixes();
                            //willen we notificaties?
                            //ivm meekijken willen we altijd notificaties
                            if (loaddata.samenwerking == 'slave') {
                                $this.notifyId = loaddata.master.id; //subs willen de broadcast van de primary
                            } else {
                                $this.notifyId = loaddata.id; //gewoon onszelf, we zijn of single
                            }
                            $this.registreerSamenwerken().then(function (samenreg) {
                                $this.updateTelling();
                                broadCastChange(true);
                            });
                        }
                    }).catch(erroralert);
                },

                /**
                 * Herlaad de modeldata uit het be, maar zonder zoom etc
                 * Vooral bij samenwerken, zie $rootscope.$on('api.notify.modelchanged') in cytocanvas.modelview
                 * @param {boolean} [clearUndobuffer] Moet de actiebuffer leeg?
                 */
                reload: function (clearUndobuffer) {
                    //geen reset, want de meeste data blijft hetzelfde
                    if (this.fullReadonly)
                    {
                        log.debug(`model.reload: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    //ophalen maar
                    const $this = this;
                    generation++; //oude saves zijn ongeldig op dit model
                    return api.getModelById($this.id).then(function (loaddata) {
                        if (loaddata) {
                            let modeldata;
                            try {
                                modeldata = JSON.parse(loaddata.json);
                            } catch (e) {
                                log.error(e);
                                return false;
                            }
                            //we zetten de relevante gegevens goed
                            $this.titel = loaddata.titel;
                            $this.userprefix = loaddata.userprefix;
                            $this.modelleerniveau = loaddata.modelleerniveau; //modelbouwcode kijkt wel of dit mag als niet 0
                            $this.bepaalModelleerNiveau();
                            $this.simulatiepreferentie = loaddata.simulatiepreferentie;
                            $this.fastest_path = api.userdata.projectdata.fastest_path_overschrijfbaar ? loaddata.fastest_path : null;
                            $this.norm = modeldata.meta && modeldata.meta.norm || false;
                            $this.versie = loaddata.versie;
                            addMeta(modeldata);
                            _data = modeldata; //clonen niet nodig, want uit json

                            actionbuffer = []; //die klopt niet meer

                            $this.loadFixes();
                            if (clearUndobuffer) {   //reinit undo
                                undoBuffer = {
                                    undoStack: [], //opgeslagen relevante tussenstappen
                                    redoStack: [] //na undo ontstaat redo
                                };
                            }
                            //zet de samenwerking opnieuw op
                            $this.samenwerking = loaddata.samenwerking;

                            if (loaddata.samenwerking === 'slave') {
                                $this.notifyId = loaddata.master.id; //slaves willen de broadcast van de aster
                            } else {
                                $this.notifyId = loaddata.id; //gewoon onszelf, we zijn master of single (en ruimte voor meekijken)
                            }

                            $this.samenwerkfeedback = parseSamenwerkfeedback(loaddata.samenwerkfeedback); //experimenteel
                            $this.registreerSamenwerken().then(function () {
                                //normtelling
                                $this.updateTelling(); //nu is de werkmodus bekend
                                broadCastChange(false); //zelfde model
                            });
                            $this.meekijkmodel = loaddata.meekijkmodel;
                        }
                    });
                },

                /**
                 * Doe wat fixes na het laden
                 */
                loadFixes: function () {
                    //normtelling aanpassen
                    if (this.norm && (this.norm.notationversion || 1) < 2 && this.norm.teller) {
                        //teller voor causals apart
                        this.norm.teller.causal = (this.norm.teller.proportionality || 0) + (this.norm.teller.influence || 0);
                        this.norm.notationversion = 2;
                    }
                    if (this.norm && (this.norm.notationversion || 1) < 3 && this.norm.teller) {
                        //geen values en exogenen in tellers
                        delete this.norm.teller.value;
                        delete this.norm.teller.q_exo;
                        delete this.norm.teller.quantity_allvalues;
                        this.norm.notationversion = 3;
                    }
                    if (this.norm && (this.norm.notationversion || 1) < 5 && this.norm.teller) {
                        //geen ineq in de teller
                        //delete this.norm.teller.ineq;
                        this.norm.notationversion = 4;
                    }

                    if (this.norm.notationversion > normnotationversion) {
                        log.warn(`Waarschuwing: notatie norm is groter dan standaard. Uit development branch?`);
                    }
                },

                /**
                 * Sla de meegestuurde standaarddata op in het huidige model en plan het opslaan in. Dit is ook het model om tellingen e.d. bij te werken.
                 * @param {object} data
                 * @param {boolean} relevant relevante wijziging (inhoudelijk)
                 * @param {boolean} [direct]  direct opslaan, zonder vertraging
                 * @param {boolean} [saveNew] ook opslaan als het model nog niet eerder is opgeslagen (wordt anders geskipt)
                 * @param {function} [imgGenerator] Generatorfunctie voor een base64-string van een jpg. Wordt gerund na succesvol opslaan, en het plaatje wordt dan async apart naar het backend gestuurd
                 * @returns {$q|Promise} promise met true of false bij direct saven en met true bij inplannen (dan dus DIRECT resolvend). Geen reject!
                 */
                save: function (data, relevant, direct, saveNew, imgGenerator) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.save: geskipt wegens fullReadonly`);
                        return $q.resolve(false); //niet doen
                    }
                    log.debug(`model.save ${direct ? 'direct' : 'op timer'}`);
                    if (this.werkmodus === 'volg') {
                        log.debug(`Skip save want volg, maar wel tellen`);
                        return $q.resolve(true);
                    }
                    if (modelSavePlanner) {
                        //cancel oude planner
                        $timeout.cancel(modelSavePlanner);
                        modelSavePlanner = null;
                    }

                    //we houden ons niet bezig met checken of de data niet gewijzigd is. De kans daarop
                    //is klein en soms komt het voor dat we zelf _data zetten en dan saven.
                    //dus geen check op hash o.i.d. meer

                    /*
                    //uitgeschakeld, want blokkeert veel
                                        //SAFETY: als het model leeg is slaan we niet op,
                                        if (_data && _data.model &&
                                            Object.keys(_data.model.elements).length && Object.keys(data.model.elements).length === 0) {
                                            log.warn('Safety check! Volkomen leeg model. We slaan niet op', _data, data);
                                            return rej(false);
                                        }
                    */

                    _data = clone(data); //ook als we het opslaan naar het backend skippen wordt dit wel onze nieuwe _data. Als detached clone om object-identity gedoe rond data position te voorkomen

                    addMeta(_data); //juiste metadata erin
                    if (relevant) {
                        //dan ook de telling
                        this.updateTelling();
                    }

                    //nieuw?
                    //waarschuwen en automatisch opslaan
                    //(relaties zijn óók nodes)
                    if ((!saveNew) && (!this.isSaved()) && _data.nodes) {
                        let l = Object.keys(_data.nodes).length;
                        if (l === 1) {
                            api.toast("MODEL_WAARSCHUWOPSLAAN");
                        }
                        if (_data.nodes && Object.keys(_data.nodes).length < 30) {
                            log.debug("model.save: skip want niet eerder gesaved");
                            //nog niet eerder opgeslagen en geen expliciet verzoek.
                            //we geven dan true terug alsof we hebben opgeslagen, omdat alles goedgegaan is
                            return $q.resolve(true);
                        } else {
                            api.toast("MODEL_WEGAANOPSLAAN");
                        }
                    }

                    //plan opslaan
                    //als er nog meer calls binnen hetzelfde rondje komen, wordt het maar 1x gedaan

                    if (direct) {
                        return saveModel(imgGenerator); //uitvoeren en promise teruggeven
                    } else { //inplannen
                        /**
                         * Timer-implementatie van save: sla het model op
                         */
                        let $this = this;

                        function plannedSave() {
                            try {
                                if ($this.werkmodus === 'volg') {
                                    //inmiddels niet meer
                                    log.debug(`*** save cancelled ivm samenwerking`);
                                    return;
                                }
                                modelSavePlanner = null;
                                saveModel(imgGenerator);
                            } catch (e) {
                                log.error(e);
                            }
                        }

                        try {
                            log.debug("Planning");
                            modelSavePlanner = $timeout(plannedSave, saveDelay);
                            log.debug(modelSavePlanner);
                        } catch (e) {
                            log.error(e);
                        }
                        return $q.resolve(true); //direct resolven na inplannen, we wachten niet op het resultaat van de save
                    }
                },

                /**
                 * Log een actie apart van het opslaan van een model. Handig voor ux-acties die ook bij volgende samenwerkers gelogd moeten worden, maar die geen actie bij de leider tot gevolg hebben. Als het model nog niet opgeslagen is, zal de actie in de buffer bewaard blijven
                 * @param {string} action
                 * @param {Object} [actionArgs] Argumenten bij de actie
                 * @param {string} [target] Route
                 * @param {string} [targettype]
                 * @param {boolean} [logNoModel] Als true wordt er ook direct gelogd als er geen opgeslagen model is (met model = null)
                 * @param {string} [volger] Als gegeven de clientid van de volger die verantwoordelijk is voor deze actie (zie logaction)
                 * @param {any} [snapshot] Snapshotdata, wordt meegestuurd als we dat nodig vinden (zie logaction
                 */
                directlogAction(action, actionArgs, target, targettype, logNoModel, volger, snapshot) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.directlogAction: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    if (this.id || logNoModel) {
                        if (snapshot && api.userdata.projectdata.actionlog_snapshots) {
                            log.debug(`log snapshot`, snapshot);
                            snapshot = JSON.stringify(snapshot);
                        }
                        else {
                            snapshot = undefined; //dan geen snapshot
                        }
                        return api.logAction(this.id, action, actionArgs, target, targettype, volger, snapshot);
                    } else {
                        log.debug(`Opslaan directe actielog in niet opgeslagen model: bewaren in buffer`);
                        return this.logaction(action, target, volger, targettype, actionArgs, false, snapshot); //geen broadcast
                    }
                },

                /**
                 * Ontkoppel het model van een opgeslagen versie, zodat we hem als nieuw - niet opgeslagen - behandelen
                 */
                ontkoppel: function () {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.ontkoppel: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    this.samenwerking = 'single';
                    this.userprefix = "";
                    this.werkmodus = 'lokaal';
                    this.meekijkmodel = null;
                    this.samenwerkfeedback = false;
                    this.id = this.notifyId = null; //nog niet opgeslagen, gaat dan ook niet vanzelf
                    this.registreerSamenwerken(true);
                    generation++; //oude saves zijn ongeldig op dit model
                },

                /**
                 * Zet metaproperties van het model zonder dat er gesaved wordt.
                 * Alleen de meegestuurde eigenschappen worden gewijzigd.
                 * @param {object} props
                 * @param {string} [props.titel]
                 * @param {number} [props.modelleerniveau]
                 * @param {number} [props.simulatiepreferentie]
                 * @param {boolean|null} [props.fastest_path]
                 * @param {*} [notifyArg] Extra argument in de notificatie, om de flow te kunnen controleren
                 */
                setProperties: function (props, notifyArg) {
                    //dit mag ook in fullreadonly
                    const $this = this;
                    const wijzigingen = {}; //de echte wijzigingen sutren we mee in de broadcast
                    //titel kan eigenlijk alleen gewijzigd worden in het mastermodel (dwingen we af via UI)
                    //maar we staan het hier toch toe, weinige relevant bij slave en zo kan het intern (via samenwerkbericht) wel
                    const propkeys = ['titel', 'modelleerniveau', 'simulatiepreferentie', 'fastest_path'];
                    propkeys.forEach(function (key) {
                        if (key in props) {
                            $this[key] = props[key];
                            wijzigingen[key] = props[key];
                        }
                    });
                    //we sturen altijd de notify, ook als er niets gewijzigd is
                    //zodat modelview zijn samenwerkings-stappen kan regelen
                    this.bepaalModelleerNiveau();

                    $rootScope.$broadcast('model.propsChanged', {
                        gewijzigd: wijzigingen,
                        notifyArg: notifyArg
                    }); //properties gewijzigd

                },

                /**
                 * Zet het effectieve modelleerniveau obv het modelleerniveau en projecteigenschappen
                 */
                bepaalModelleerNiveau: function () {
                    log.debug(`bepaalModelleerniveau ${this.modelleerniveau} ${typeof this.modelleerniveau} ${api.userdata.projectdata.modelleerniveau}`)
                    this.effectief_modelleerniveau = ((this.modelleerniveau === 0) || (!api.userdata.projectdata.modelleerniveau_overschrijfbaar)) ? api.userdata.projectdata.modelleerniveau : this.modelleerniveau;
                    log.debug(`effectief modelleerniveau`, this.effectief_modelleerniveau);
                },

                /**
                 * Bereken het 2/3/4/5 modelleerniveau op basis van het effectief modelleerniveau
                 */
                platModelleerNiveau: function () {
                    this.bepaalModelleerNiveau();
                    let niveauplat = 2; //default
                    if (model && model.effectief_modelleerniveau) {
                        niveauplat = Math.floor(model.effectief_modelleerniveau / 10);
                    } else if (api.userdata && api.userdata.projectdata.modelleerniveau) {
                        niveauplat = Math.floor(api.userdata.projectdata.modelleerniveau / 10);
                    }
                    return niveauplat;
                },

                /**
                 * Return de laatst ingelezen / opgeslagen data (als clone)
                 * @return {{}}
                 */
                data: function () {
                    return clone(_data);
                },

                /**
                 * Open de property-dialog op dit model
                 * @param {string} [aanhefLocale] Locale-string voor aanheftekst in de dialoog
                 * @param {object} [overwriteValues] properties die anders moeten zijn dan de huidige modelproperties, bijv alternatieve naam
                 * @param {boolean} voorKopie = false. Als true dan worden de ingestelde eigenschappen niet in het model toegepast, maar alleen teruggeven. Ook mag er dan meer
                 * @returns Promise.<boolean|object> Bij ok wordt een object met de nieuwe gegevens teruggegeven (die zijn als noSave niet gegeven is dan ook op het model toegepast). Bij cancel / fout wordt false teruggegeven
                 * Let op: ook zonder nietToepassen moet de aanroeper zelf bepalen of het model opgeslagen moet worden
                 */
                propertiesDialog: function (aanhefLocale, overwriteValues, voorKopie) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.propertiesDialog: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    const $this = this;
                    const mdata = {
                        titel: this.titel,
                        modelleerniveau: this.modelleerniveau,
                        simulatiepreferentie: this.simulatiepreferentie,
                        fastest_path: this.fastest_path
                    };
                    if (overwriteValues) {
                        angular.extend(mdata, overwriteValues);
                    }
                    const prompt = $uibModal.open({
                        animation: true,
                        templateUrl: 'app/states/modelprops/modelprops.html',
                        controller: 'modelPropsController',
                        backdrop: 'static',
                        resolve: {
                            initvalues: {
                                aanhef: aanhefLocale,
                                model: mdata,
                                wijzigtitel: voorKopie || this.samenwerking !== 'slave' //niet bij slave, tenzij voor kopie
                            }
                        }
                    });
                    /**
                     * wat krijgen we terug als we het modal loadmenu hebben gesloten?
                     */
                    return prompt.result.then(function (newdata) {
                            if ((!voorKopie) && newdata) {
                                //er is op ok geklikt: toepassen dus
                                $this.setProperties(newdata);
                            }
                            return newdata; //of dat nou een object of false (cancel) is
                        },
                        function () {
                            //modal dismissed
                            return false;
                        }
                    );
                },

                /***************** samenwerking **********************/
                /**
                 * Registreer of deregistreer een model voor samenwerking en zet de werkmodus goed
                 * Returnt promise dit resolvt als dit klaar is
                 * @param {boolean} [deregistreer] Als true dan is dit een deregistreer. De werkmodus wordt dan op 'lokaal' gezet als we inderdaad geen geladen model zijn
                 */
                registreerSamenwerken: function (deregistreer) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.registreerSamenwerken: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    var $this = this;

                    return api.registreerSamenwerken(
                        deregistreer ? null : this.notifyId,
                        (!deregistreer) && this.meekijkmodel && this.id).then(function (regresult) {
                        //nog in hetzelfde model?

                        if (regresult.model === $this.notifyId) //werk ook bij null
                        {
                            if ($this.werkmodus !== regresult.werkmodus) {
                                $this.werkmodus = regresult.werkmodus;
                                $rootScope.$broadcast('model.werkmodusChanged');
                                //fix de normmatch, die moet misschien nu juist wel, of kan gecancelled worden
                                $this.maakNormmatch();
                            }
                        }
                        return true; //promise-return
                    }).catch(e => {
                        log.debug(`Registreersamenwerken niet gelukt`, e);
                        return false
                    }); //niet ingelogd?
                },

                /**
                 * Een client van deze service heeft nieuwe samenwerkfeedback van het backend ontvangen, wij parsen het en onthouden het
                 * @param samenwerkfeedback
                 */
                setSamenwerkfeedback: function (samenwerkfeedback) {
                    this.samenwerkfeedback = parseSamenwerkfeedback(samenwerkfeedback);
                },

                /**
                 *  Laat api een samenwerkbericht sturen. Leiders en volgers sturen acties die moeten of zijn uitgevoerd.
                 * Als een volger dat stuurt, gaat het naar de leider, die hem mogelijk uitvoert
                 * Als een leider het stuurt, is het uitgevoerd en moeten de volgers meedoen
                 * @param {object[]} acties Lijst met acties met in elk geval de actienaam in prop actie, en extra nodige argumenten
                 * @param {string} [originele_afzender] mee te sturen id van de originele afzender die deze berichtreeks op gang bracht
                 */
                samenwerkbericht: function (acties, originele_afzender) {
                    if (this.werkmodus === 'lokaal') {
                        return; //niets te doen
                    }
                    if (this.fullReadonly)
                    {
                        log.debug(`model.samenwerkbericht: geskipt wegens fullReadonly`);
                        return;
                    }
                    //als we leider zijn, sturen we ook een changeid mee
                    //die slaan we op in alle changeid's die nog te saven zijn
                    var changeId = false;
                    var extra = {};
                    if (this.werkmodus === 'leid') {
                        changeId = api.unique + '-' + (Date.now().toString(16)); //nieuwe changeId
                        this.samenwerkchange(changeId); //in de volgende save melden dat deze erbij hoort
                        //undostatus
                        extra = {
                            canUndo: this.canUndo(),
                            canRedo: this.canRedo()
                        };
                    }
                    return api.samenwerkbericht(this.werkmodus, changeId, this.id, this.notifyId, originele_afzender, acties, extra);
                },

                /**
                 * Verstuur onze undostatus naar volgers als we leider zijn
                 */
                verzendUndostatus: function () {
                    if (this.werkmodus === 'leid') {
                        api.samenwerkUndostatus(this.canUndo(), this.canRedo());
                    }
                },

                /**
                 * Voeg een changeid toe aan de lijst met verwerkte changes. Bij leid worden deze meegestuurd bij het opslaan, bij volg worden ze gebruikt in de modelchanged handler om te controleren of we bij zijn qua changes (zie modelviewController)
                 * @param changeId
                 */
                samenwerkchange: function (changeId) {
                    this.changes.push(changeId);
                    //that's all folks
                },

                /**
                 * Reset de lijst met samenwerkchanges
                 */
                resetSamenwerkchanges: function () {
                    this.changes = [];
                },

                /***************** actielog ********************************/
                /**
                 * Voeg een actie toe aan de actionbuffer om op te slaan met het model
                 * @param action string Actienaam
                 * @param target string Beschrijving van de target, meestal bij nodes route(node)
                 * @param {string | null} [volger] Bij samenwerking de clientid van de volger, zodat het be straks de juiste uid eraan kan koppelen
                 * @param {string} [targettype] concrete typenaam van target, voor zover van toepassing. Vooral voor snelle statistieken
                 * @param [args] relevante argumenten
                 * @param {boolean} [broadcastChange] = true broadcast model.contentChanged-event
                 * @param {any} [snapshot] Snapshotdata, wordt meegestuurd als we dat nodig vinden
                 */
                logaction: function (action, target, volger, targettype, args, broadcastChange, snapshot) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.logaction: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    log.debug(`logaction(${action}, ${target}, ${volger}, ${targettype}`, args);
                    const actionlog = {
                        ts: Date.now(),
                        action: action,
                        target: target,
                        volger: volger || null,
                        targettype: targettype,
                        arguments: args || {}
                    };
                    if (snapshot && api.userdata.projectdata.actionlog_snapshots)
                    {
                        snapshot = JSON.stringify(snapshot);
                        //en dan nog alleen als we niet al dezelfde hebben
                        let vorige = actionbuffer.findLast(al => al.snapshot);
                        if (! (vorige && vorige.snapshot === snapshot))
                        {
                            //anders of eerste
                            actionlog.snapshot = snapshot;
                        }
                    }
                    actionbuffer.push(actionlog);
                    if (broadcastChange || (typeof (broadcastChange) == "undefined")) {
                        $rootScope.$broadcast('model.contentChanged');
                    }
                },

                /**
                 * Geef de huidige actionbuffer terug (shallow kopie)
                 * @returns {*[]}
                 */
                get actionbuffer() {
                  return [...actionbuffer];
                },
                /************* UNDO / REDO *********************
                 * Beschikbaar als $scope.model er is
                 */

                /**
                 * Bewaar de huidige _data voor de undo. Dit dus aanroepen vóór logische wijzigingen
                 * want het is de state waar je naar terug wilt
                 * in _data zit de huidige logische versie, want ná logische wijzigingen moet je savemodel doen
                 * na setUndoPoint save je weer, dus dan is _data anders
                 */
                setUndoPoint: function () {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.setUndoPoint: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    undoBuffer.undoStack.push(clone(_data)); //altijd ontkoppelen
                    //deze wijziging maakt redo onmogelijk
                    undoBuffer.redoStack = [];
                    //max
                    while (undoBuffer.undoStack.length > maxUndo) {
                        undoBuffer.undoStack.shift(); //oudste weg
                    }
                    this.verzendUndostatus(); //laat volgers het ook weten
                },
                /**
                 * True als undo bruikbaar is. Als we volger zijn, nemen we de laatst bekende waard van de leider, anders onze undoBuffer
                 * @return {boolean}
                 */
                canUndo: function () {
                    if (this.fullReadonly)
                    {
                        return false; //niet doen
                    }
                    return this.werkmodus === 'volg' ? this.leiderCanUndo : undoBuffer.undoStack.length > 0;
                },
                /**
                 * True als redo bruikbaar is
                 * @return {boolean}
                 */
                canRedo: function () {
                    if (this.fullReadonly)
                    {
                        return false; //niet doen
                    }
                    return this.werkmodus === 'volg' ? this.leiderCanRedo : undoBuffer.redoStack.length > 0;
                },

                /**
                 * laatste actie ongedaan maken
                 * ons huidige logische model is _data (want ná een logische wijziging save)
                 * de vorige versie is de top van de undo-stack
                 * opslaan e.d. moet aanroeper regelen
                 */
                undo: function () {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.undo: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    if (this.werkmodus === 'volg') {
                        //dan weigeren we
                        return;
                    }
                    if (this.canUndo()) {
                        //de huidige toestand naar de redo
                        var vorige = clone(_data);
                        undoBuffer.redoStack.push(vorige);
                        //laatste van de stack
                        _data = undoBuffer.undoStack.pop();
                        broadCastChange(false); //en laat weten dat er een wijziging is
                        this.updateTelling();
                        this.verzendUndostatus(); //laat volgers het ook weten

                    }
                },

                /**
                 * Terug naar status voor vorige undo
                 * opslaan e.d. moet aanroeper regelen
                 */
                redo: function () {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.redo: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    //dit is een omgekeerde undo
                    if (this.werkmodus === 'volg') {
                        //dan weigeren we
                        return;
                    }
                    if (this.canRedo()) {
                        //de huidige toestand bovenop de undostack, niet setUndoPoint want redoStack blijft geldig
                        undoBuffer.undoStack.push(clone(_data)); //ontkoppelen
                        _data = undoBuffer.redoStack.pop(); //die is al ontkoppeld
                        broadCastChange(false); //en laat weten dat er een wijziging is
                        this.updateTelling();
                        this.verzendUndostatus(); //laat volgers het ook weten
                    }
                },

                /**
                 * Expliciet verzoek om data te zetten, bijvoorbeeld vanwege samenwerking
                 * @param modeldata
                 */
                setData: function (modeldata) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.setData: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    _data = clone(modeldata);
                    addMeta(_data);
                    broadCastChange(false);
                    this.updateTelling();
                },

                /***************** norm ***********************************/
                /**
                 * Werk de telling per norm-elementtype bij op basis van de huidige modeldata in deze service
                 */
                updateTelling: function () {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.updateTelling: geskipt wegens fullReadonly`);
                        //TODO: uitzoeken of dit toch niet moet
                        return; //niet doen
                    }
                    log.debug(`updateTelling`, this.werkmodus);
                    //volgers wachten op de leider
                    if (this.werkmodus === "volg") {
                        log.debug(`updateTelling: we wachten op de leider`);
                        return;
                    }
                    const $this = this;
                    $this.telling = {};
                    $this.naamtelling = {
                        norm: 0,
                        aantal: 0
                    };
                    if ($this.norm && $this.norm.teller) {
                        let normtypes = Object.keys($this.norm.teller);
                        normtypes.forEach(function (nt) {
                            $this.telling[nt] = {
                                aantal: 0,
                                fouten: null
                            };
                        });

                        angular.forEach(_data.model.types, function (elements, modeltype) {
                            //op zoek naar het juiste element in de telling
                            //vanwege de verschillende modelleerniveaus zoeken we door en kan een element bij meerdere meetellen
                            normtypes.forEach(function (normtype) {
                                if (elementService.isSubtypeOf(modeltype, normtype)) {
                                    $this.telling[normtype].aantal += elements.length;
                                    //geen break: kan bij meerdere horen (modelleerniveaus)
                                }
                            });
                        });

                        //de namen:
                        Object.keys($this.norm.namen).forEach(function (naam) {
                            $this.naamtelling.norm += $this.norm.namen[naam]; //aantal verwacht
                            //elementen met deze naam tellen mee met de telling
                            //maar tot maximaal het normaantal, want dubbel gebruik telt niet mee
                            let teller = 0;
                            angular.forEach(_data.model.elements, function (element) {
                                let telnaam = element.nodename;
                                //speciaal
                                if (element.type === "quantity_space_point" && element.isZero) {
                                    telnaam = "___qszero";
                                }
                                if (telnaam == naam) {
                                    teller++;
                                }
                            });
                            //maximaal het normaantal
                            $this.naamtelling.aantal += Math.min(teller, $this.norm.namen[naam]);
                        });
                        this.stuurNorm(); //stuur maar op, met de huidige interpretaties
                    }

                    //we gaan een norm maken als dat volgens de config moet:
                    //als het vraagteken er is of "highlight alle fouten" aan staat
                    if (this.norm && api.userdata && api.userdata.projectdata && (api.userdata.projectdata.norm_foutfeedback || api.userdata.projectdata.norm_foutfeedback_highlight)) {
                        this.maakNormmatch();
                    }

                    log.debug(`updateTelling`, this.norm.teller, this.telling);
                },

                /**
                 * Laat de normmatch-worker een optimale interpretatie maken, gegeven het normmodel en het huidige werkmodel
                 * Er moet al een normtelling zijn, dit wordt aangeroepen
                 * vanuit updateTelling
                 */
                maakNormmatch: function () {
                    /*log.debug(`MaakNormMatch: uitgeschakeld`);
                    return;*/

                    let $this = this;
                    this.setNormdata(); //weg met de oude
                    if (normMatchWorker) {
                        log.debug(`Kill runnende normMatchWorker`);
                        normMatchWorker.terminate();
                        normMatchWorker = null;
                    }

                    if (!this.norm) {
                        log.debug('Geen norm - niets te doen');
                        this.setNormdata(null); //weg
                        return;
                    }

                    //niet als we volgen
                    //dit kan te vroeg komen, trouwens, dan moet het op het einde
                    if (this.werkmodus === "volg") {
                        log.debug(`maakNormMatch: we wachten op de leider`);
                        return;
                    }
                    if (!this.norm.from && this.norm.from.content) {
                        log.error(`Oud model obv normmodel - kan niet geinterpreteerd worden.`);
                        log.debug(this.norm);
                        return;
                    }

                    //run de worker
                    try {
                        normmatchgeneration++;
                        let workergeneratie = normmatchgeneration; //de workergenatatie waarmee we werken
                        normMatchWorker = new Worker("app/workers/dist/model.normmatch.js");
                        normMatchWorker.onmessage = ev => {
                            log.debug('normMatchWorker return generatie', workergeneratie, normmatchgeneration);
                            if (workergeneratie !== normmatchgeneration) {
                                log.debug(`Normmatchworker klaar maar op oude generatie. Stop.`);
                                return;
                            }
                            if (this.werkmodus === "volg") {
                                log.debug('normMatchWorker returnt, maar we volgen inmiddels');
                                return; //niets dus
                            }
                            this.setNormdata(ev.data); //stuurt het ook naar de volgers
                            if (this.normmatch) {
                                output(this.normmatch); //debug
                            }
                            normMatchWorker.terminate(); //klaar
                            normMatchWorker = null; //en weg
                            //buiten de angular eventpomp om:
                            $rootScope.$digest();
                        };

                        log.debug(`run worker generatie ${workergeneratie}`);
                        normMatchWorker.postMessage({
                            norm: this.norm.from.content.elements,
                            werk: _data.model.elements,
                            modelleerniveau: this.modelleerniveau
                        });
                    } catch (e) {
                        if (normMatchWorker) {
                            normMatchWorker.terminate();
                            normMatchWorker = null;
                        }
                        console.error(e);
                    }

                    //tijdelijke consoleoutput
                    function output(interpretatie) {
                        let normels = Object.values($this.norm.from.content.elements);
                        let werkels = Object.values(_data.model.elements);

                        /*	console.warn(`**** INTERPRETATIE NORM-WERK ****`);
                            console.warn(`Score: ${interpretatie.score}`);
                            console.warn(`Mappings:`);*/

                        for (let m of interpretatie.mappings) {
                            // console.warn(`---`);
                            let normset, werkset;

                            //parents?
                            if (m.norm.parentId && (!interpretatie.mappings.find(pm => pm.norm.id === m.norm.parentId && pm.werk.id === m.werk.parentId))) {
                                normset = normels;
                                werkset = werkels;
                            }
                            // console.warn(`Normmodel: ${beschrijf(m.norm, normset)}`);
                            //	log.debug(m.norm);
                            // console.warn(`Werkmodel: ${beschrijf(m.werk, werkset)}`);
                            //	log.debug(m.werk);
                            // console.warn(`Matchregels: ${m.regels.join(', ')}`);
                            // console.warn(`Score: ${m.score}`);
                        }

                        // console.warn(`*********************************`);
                        // console.warn("**** FOUTEN ****");
                        for (let fout of Object.values(interpretatie.foutmodel).filter(f => f.fouten.length)) {
                            // console.warn(`${fout.fouten.join(", ")}:`, beschrijf(fout.el, werkels));
                            if (fout.mapping && fout.mapping.norm) {
                                // console.log(beschrijf(fout.mapping.norm, normels));
                            }
                        }
                        /*console.warn('**** HELEMAAL GOED ****');
                        for (let fout of Object.values(interpretatie.foutmodel).filter(f => !f.fouten.length)) {
                            console.warn(beschrijf(fout.el, werkels));
                            if (fout.mapping && fout.mapping.norm) {
                                console.log(beschrijf(fout.mapping.norm, normels));
                            }
                        }*/
                    }

                    /**
                     * Tijdelijke helper voor betere console output
                     * @param node
                     * @param [parentsearch] Als gegeven, dan array van elementen, om een parentsearch te doen (recursief)
                     * @return {string}
                     */
                    function beschrijf(node, parentsearch) {
                        let nodename = node.nodename || null;
                        if (node.type == 'quantity_space_point' && node.isZero) {
                            nodename = 'zero';
                        }
                        let desc = (node.type || '_');
                        if (nodename) {
                            desc += ": " + nodename;
                        }
                        if (parentsearch) {
                            if (elementService.isSubtypeOf(node.type, 'quantity_space_element') || elementService.isSubtypeOf(node.type, 'derivative_element')) {
                                let q = vindQuantity(node.id, parentsearch);
                                desc += ` bij ${beschrijf(q, parentsearch)}`;
                            } else if (node.parentId) {
                                let parent = parentsearch.find(p => p.id === node.parentId);
                                if (parent) {
                                    desc += ` van ${beschrijf(parent, parentsearch)}`; //recursiefje
                                }
                            }
                        }
                        return desc;
                    }

                    function vindQuantity(id, collectie) {
                        let el = collectie.find(e => e.id === id);
                        if (elementService.isSubtypeOf(el.type, 'quantity')) {
                            return el;
                        }
                        if (el.parentId) {
                            return vindQuantity(el.parentId, collectie);
                        } else {
                            return null;
                        }
                    }
                },

                /**
                 * Nieuwe norminterpretaties en of telling gegenereerd of van de leider doorgekregen. Dit zetten we dus. In leider en volger
                 * @param interpretaties = null
                 * @param tellingen = null
                 */
                setNormdata(interpretaties, tellingen) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.setNormdata: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    if (interpretaties && interpretaties.length) {
                        this.norminterpretaties = interpretaties;
                        //foutmodel gewijzig?
                        let oudfoutmodel = this.normmatch ? this._foutmodelfouten(this.normmatch.foutmodel) : [];
                        let nieuwfoutmodel = this._foutmodelfouten(this.norminterpretaties[0].foutmodel);
                        if (!angular.equals(oudfoutmodel, nieuwfoutmodel)) {
                            //direct loggen
                            this.directlogAction('errormodel', nieuwfoutmodel);
                        }
                        this.normmatch = this.norminterpretaties[0];
                        log.debug('Nieuwe normmatch gezet', this.normmatch);
                    } else {
                        this.norminterpretaties = [];
                        this.normmatch = null;
                    }
                    if (tellingen) {
                        this.telling = tellingen.telling;
                        this.naamtelling = tellingen.naamtelling;
                    }
                    //naar de volgers?
                    this.stuurNorm();
                    //afhankelijke data
                    this.foutniveau = 0; //reset
                    //bijwerken van fouten
                    this.updateFouttelling();
                    $rootScope.$broadcast('model.norminterpretatieChanged');
                },

                /**
                 * Helper bij setNorminterpretaties. Filter een foutmodel op alleen elementen met fouten
                 * @param foutmodel
                 */
                _foutmodelfouten(foutmodel) {
                    return Object.keys(foutmodel).filter(id => foutmodel[id].fouten.length).map(id => foutmodel[id]);
                },

                /**
                 * Als we leider zijn, stuur de interpretaties naar de volgers
                 */
                stuurNorm() {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.stuurNorm: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    //zijn wij leiders?
                    if (this.werkmodus === "leid") {
                        //naar de volgers, inclusief huidige interpretaties, en meteen ook de telling (kan ook apart via normtelling)
                        this.samenwerkbericht([{
                            actie: 'norm',
                            interpretaties: this.norminterpretaties,
                            telling: {
                                telling: this.telling,
                                naamtelling: this.naamtelling
                            }
                        }]);
                    }
                },

                /**
                 * UX-functie Zet de normmatch op een andere interpretatie en werk de telling bij
                 * @param interpretatieIndex
                 * @param vanLeider
                 * @param leiderFoutniveau
                 */
                selectInterpretatie(interpretatieIndex, vanLeider, leiderFoutniveau) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.selectInterpretatie: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    log.debug(`selectInterpretatie ${interpretatieIndex}`);
                    if (this.werkmodus === "volg" && !vanLeider) {
                        //stuur het naar de leider
                        this.samenwerkbericht([{
                            actie: "selectInterpretatie",
                            interpretatieIndex: interpretatieIndex
                        }]);
                        return;
                    }
                    const maxfoutniveau = 1; //was 3

                    if (interpretatieIndex < this.norminterpretaties.length) {
                        let nieuwmatch = this.norminterpretaties[interpretatieIndex];
                        if (vanLeider) {
                            this.foutniveau = leiderFoutniveau;
                        } else if (nieuwmatch === this.normmatch) {
                            //meer laten zien
                            if (++this.foutniveau > maxfoutniveau) {
                                this.foutniveau = 0;
                            }
                        }
                        this.normmatch = nieuwmatch;
                    } else {
                        this.normmatch = null;
                        this.foutniveau = 0;
                    }
                    // log.debug(this.normmatch);
                    log.debug(`Foutniveau ${this.foutniveau}`);
                    this.updateFouttelling();
                    $rootScope.$broadcast('model.norminterpretatieChanged');
                    if ((!vanLeider) && this.werkmodus === "leid") {
                        this.samenwerkbericht([{
                            actie: 'selectInterpretatie',
                            interpretatieIndex: interpretatieIndex,
                            foutniveau: this.foutniveau
                        }]);
                    }
                },
                /**
                 * UX functie: reset het foutniveau, en meldt dat aan volgerd
                 * @param {boolean} [vanLeider]
                 */
                resetFoutniveau(vanLeider) {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.resetFoutniveau: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    //shortcut: we gaan ervan uit dat alles klopt als het foutniveau 0 is
                    log.debug(`resetFoutniveau`);
                    if (this.foutniveau === 0) {
                        return;
                    }
                    if (this.werkmodus === "volg" && (!vanLeider)) {
                        //stuur het naar de leider
                        this.samenwerkbericht([{
                            actie: "resetFoutniveau"
                        }]);
                        return;
                    }
                    this.foutniveau = 0;
                    this.updateFouttelling();
                    $rootScope.$broadcast('model.norminterpretatieChanged');
                    if ((!vanLeider) && this.werkmodus !== "volg") {
                        //stuur het naar de volgers
                        this.samenwerkbericht([{
                            actie: "resetFoutniveau"
                        }]);
                    }

                },

                updateFouttelling() {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.updateFouttelling: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    let prevtelling = angular.copy(this.telling);
                    for (let nt of Object.keys(this.telling)) {
                        if (this.telling[nt]) {
                            this.telling[nt].fouten = this.normmatch ? Object.values(this.normmatch.foutmodel).filter(f => f.fouten && f.fouten.length && f.el && elementService.isSubtypeOf(f.el.type, nt)).length : null;
                        }
                    }
                    this.stuurNormTelling();
                    //gewijzigd,dan ook loggen in de actionlog
                    if (!angular.equals(prevtelling, this.telling)) {
                        //direct loggen
                        this.directlogAction('errorcount', {telling: this.telling, norm: this.norm.teller});
                    }
                    // log.debug(`telling`, this.telling);
                },

                /**
                 * Stuur bijgewerkte tellinggegevens
                 */
                stuurNormTelling() {
                    if (this.fullReadonly)
                    {
                        log.debug(`model.stuurNormTelling: geskipt wegens fullReadonly`);
                        return; //niet doen
                    }
                    if (this.isSaved() && this.werkmodus !== "volg") {
                        // log.warn('savenormtelling');
                        api.saveNormTelling(this.id, this.telling, this.norm.teller);
                    }
                }
            };
        return model;
    }

])
;
