/**
 * helper-class bij het runnen van regels voor simfeedback.
 * Deze kan op basis van de model cy tests runnen (algoritmische regels)
 * en hierover informatie geven.
 *
 * Moet 1 keer gemaakt worden in cytocanvas.modelview.controller
 */

class Simfeedbackengine {
    constructor(log, model, cy, api, elementService) {
        log.debug("Simfeedbackengine");
        //overnemen services
        this.log = log;
        this.model = model;
        this.cy = cy;
        this.api = api;
        this.elementService = elementService;
        this.projectdata = {}; //doen we bij het runnen.

        //de feedback: voorbereid per type zodat er een volgorde is
        this.feedback = {
            feedback_neg: [], //feedbackloop loopt negatief, zelfdovend
            feedback_pos: [], //feedbackloop loopt positief: zelfversterkend            feedback_neg_I: [], //feedbackloop loopt negatief, zelfdovend, zonder I (niveau 40+)
            feedback_pos_I: [], //feedbackloop loopt positief: zelfversterkendm zonder I (niveau 40+)
            d_beginwaarde_missend: [], //een Q aan het begin van een causale keten heeft geen beginwaarde op de afgeleide
            d_beginwaarde_blokkerend: [], //een Q niet aan het begin van een causale keten heeft wel een beginwaarde op afgeleide
            exogeen_blokkerend: [], //een Q niet aan het begin van een causale keten heeft een beginwaarde op afgeleide door een exogeen
            beginwaarde_toekennen: [], //een Q met waardenbereik heeft geen direct of indirecte waardetoekenning op dat bereik
            beginwaarde_blokkerend: [], //een Q met waardenbereik heeft én direct én indirect een waardetoekenning op dat bereik
            i_heeft_beginwaarde_nodig: [], //een Q waar een I uitkomt moet een beginwaarde hebben
        }; //key type, value array met arrays van te highlighten elementen (de id's, niet de elementen ivm samenwerken)


        //helpers
        this.beschikbaar = false;
    }

    reset() {
        //leeg alle arrays, maar bewaar de keys
        for (let type of Object.keys(this.feedback)) {
            this.feedback[type] = [];
        }
        this.beschikbaar = false;
    }

    //Run alle regels die we hebben
    run() {
        this.projectdata = this.api && this.api.userdata && this.api.userdata.projectdata || {}; //handiger
        this.reset();
        //er is geen hoofdflag. Elke check kijkt naar zijn eigen flag in this.projectdata
        this.log.debug("Runnen Simfeedback");
        this.checkFeedbackloops();
        this.checkBeginwaarden();
        this.log.debug("Simfeedback", this.feedback);

        //zet onze beschikbaarheid
        this.checkBeschikbaar();
    }

    /**
     * Extern zetten van onze feedback: vooral door een leider in samenwerking
     * @param feedback
     */
    setFeedback(feedback) {
        this.feedback = angular.copy(feedback);
        this.checkBeschikbaar();
    }

    /**
     * Set onze beschikbaar flag
     */
    checkBeschikbaar() {
        //beschikbaar als er iets te melden valt. De project-flags gebruiken we alleen voor het runnen
        this.beschikbaar = Object.keys(this.feedback).some(k => this.feedback[k].length);
    }

    ////////////////////////// Info en helpers ///////////////////////


    ///////////////////////// Regels voor vinden van feedback //////////////
    //dit zouden we ook weer kunnen afsplitsen in objecten, maar ach

    /**
     * Check op feedbackloops: +/- causals die in een rondje gaan
     */
    checkFeedbackloops() {
        //juiste modelleerniveau?
        if ((!this.projectdata.feedb_feedbackloop) || (this.model.effectief_modelleerniveau < 20) || (this.model.effectief_modelleerniveau >= 50)) {
            this.log.debug(`simfeedback: check feedbackloops uitgeschakeld`);
            return;
        }
        //1: doe alle proportionalities
        //relaties zijn nodes met een paar edges ertussen

        //LET OP: alle conditionals uitgesloten, en ook multiply en division
        // let selector = this._selectorForTypes(['influence_positive', 'influence_negative', 'proportionality_positive', 'proportionality_negative']);
        let selector = this._selectorForTypes(['proportionality_positive', 'proportionality_negative']);
        this.log.debug(`simfeedback selector`, selector);
        //we maken wat handige dataobjecten
        let causals = [];
        for (let el of this.cy.nodes(selector).toArray()) {
            let t = el.data('type');
            //het causal-object:
            let causal = {
                //van: cy.$id(el.data('from')).data('label'),
                //naar: cy.$id(el.data('to')).data('label'),
                id: el.id(), //niet het element zelf, ivm samenwerking
                from: el.data('from'),
                to: el.data('to'),
                type: t,
                sign: ['proportionality_positive'].includes(t) ? 1 : -1 //vermenigvuldigbaar
            };
            causals.push(causal);
        }

        // this.log.debug(`causals`, causals);
        /**
         *
         * @type {string[]}
         */
        let gedaan = []; //elementen (causals) die al gedaan zijn
        let loops = [];
        //we lopen over elke causal. De causals die we al bekeken hebben (als startpunt) hoeven niet meer in
        //andere rondjes voor te komen, want dat rondje kennen we dan al gegarandeerd
        //de routes die we vinden moeten dan ook echt vanaf deze causal.
        //zelfs als we tussendoor een andere route vinden
        for (let causal of causals) {
            //we maken een agenda voor het uitbreiden van de loop
            //die eindigt als we een object dubbel hebben
            let agenda = [[causal]];
            while (agenda.length) {
                //uitbreiden: vind de volgende stap van de voorste
                let route = agenda.shift(); //voorste eraf
                let tail = route[route.length - 1];
                //vind alle causals die vertrekken vanaf ons eindpunt
                for (let volgende of causals.filter(cv => cv.from === tail.to)) {
                    //is dit een al uitgewerkte stap?
                    if (gedaan.includes(volgende)) {
                        continue; //dit rondje kennen we dan al, als er een rondje is (dan waren we hoe dan ook langs alle causals daarna gekomen)
                    }
                    if (volgende === route[0]) {
                        //LOOP!!
                        let loop = {
                            route: route,
                            sign: route.reduce((sign, stap) => sign * stap.sign, 1)
                        };
                        loop.type = (loop.sign > 0) ? "pos" : "neg"; //voor het gemak
                        loops.push(loop);

                        //skip expliciet rondjes die we vinden maar die niet vanaf het begin zijn
                        //en skip ook als volgende.to al ergens in de route zit
                    } else if ((!route.includes(volgende)) && (!route.some(el => volgende.from === el.from))) {
                        //okee, uitbreiden maar
                        agenda.push([...route, volgende]); //loopt dood of
                    }
                }
            }
            //toekomstige rondjes met de causal die we nu hebben uitgewerkt.
            //zijn gegarandeerd gedraaide rondjes, dus dezelfde:
            gedaan.push(causal);
        }
        //even uitwerken
        let feedback_neg = loops.filter(l => l.type === 'neg').map(l => l.route.map(stap => stap.id));
        let feedback_pos = loops.filter(l => l.type === 'pos').map(l => l.route.map(stap => stap.id));
        if (this.model.effectief_modelleerniveau < 40) {
            this.feedback.feedback_neg = feedback_neg;
            this.feedback.feedback_pos = feedback_pos;
        } else {
            this.feedback.feedback_neg_I = feedback_neg;
            this.feedback.feedback_pos_I = feedback_pos;
        }
    }

    /**
     * Check of elke Q wel een beginwaarde heeft + ook een d-waarde als hij een afgeleide heeft
     */
    checkBeginwaarden() {
        //staat dit aan en juiste modelleerniveau?
        if ((!this.projectdata.feedb_beginwaarden) || (this.model.effectief_modelleerniveau < 20) || (this.model.effectief_modelleerniveau >= 50)) {
            this.log.debug(`simfeedback: check beginwaarden uitgeschakeld`);
            return;
        }
        //het is veel eenvoudiger geworden. Elke Q moet een d-value hebben + een Q-value hebben (als er een QS is)

        //even wat informatie verzamelen
        for (let Q of this.cy.nodes(this._selectorForTypes(['quantity'])).toArray()) {
            let Q_id = Q.id();
            //D-value
            //lokaal?
            let dvalue = this._vindChild(Q, 'derivative_value');
            let exo = this._vindChild(Q, 'q_exo');
            let extern = this._dBeginwaardeExtern(Q);

            if (extern && dvalue) {
                this._pushFeedbackAlsNiet("d_beginwaarde_blokkerend", Q_id, [Q_id, dvalue.id()]);  //de Q en de waarde
            }
            if (extern && exo) {
                this._pushFeedbackAlsNiet("exogeen_blokkerend", Q_id, [Q_id, exo.id()])
            }
            if (!(extern || dvalue || exo)) {
                //er is niets
                this._pushFeedbackAlsNiet("d_beginwaarde_missend", Q_id, [Q_id]);
            }

            //nu de values, maar alleen als er een QS is
            let QS = this._vindChild(Q, 'quantity_space');
            if (QS) {
                let value = this._vindChild(Q, 'quantity_value') || this._vindChild(Q, 'quantity_allvalues');
                extern = this._qBeginwaardeExtern(Q);
                if (value && extern) {
                    //dubbele beginwaarde
                    this._pushFeedbackAlsNiet("beginwaarde_blokkerend", Q_id, [Q_id, value.id()]);
                } else if (!(value || extern)) {
                    //geen beginwaarde
                    this._pushFeedbackAlsNiet("beginwaarde_toekennen", Q_id, [Q_id]);
                }
            }
        }
    }

    /**
     * Helper bij checkBeginwaarden,voor recursie. True als er een externe bron voor de beginwaarde van de afgeleide is
     * @param Q Quantity
     * @param {string[]} [skip] Ids van Q's waarvan we al kijken of de waarde extern is (loop-stop)
     * @private
     */
    _dBeginwaardeExtern(Q, skip) {
        skip = skip || [];
        let Q_id = Q.id();
        let dqs = this._vindChild(Q, 'derivative');
        let dqs_id = dqs.id();


        //we gaan het even uitzoeken
        //causals naar de Q
        if (this.cy.nodes(this._selectorForTypes(['causal'], true)).is(`[to = '${Q_id}']`)) {
            return true;
        }
        //gerichte correspondenties naar de d
        if (this.cy.nodes(this._selectorForTypes(['correspondence_dqs_directed', 'correspondence_dqs_directed_reverse'])).is(`[to = '${dqs_id}']`)) {
            return true;
        }
        //exacte uitkomst van een calculus
        if (this.cy.nodes(this._selectorForTypes(['d_ineq_eq'])).some(ineq =>
            ineq.data('to') === dqs_id && this.elementService.isSubtypeOf(this.cy.$id(ineq.data('from')).data('type'), 'd_calc') ||
            ineq.data('from') === dqs_id && this.elementService.isSubtypeOf(this.cy.$id(ineq.data('to')).data('type'), 'd_calc')
        )) {
            return true;
        }
        //bidirectionele correspondenties: hiervoor geldt dat de andere kant zelf ook weer een waarde moet
        //krijgen (en niet van deze)
        for (let corr of this.cy.nodes(this._selectorForTypes(['correspondence_dqs_normal', 'correspondence_dqs_reverse'])).filter(`[from = '${dqs_id}'],[to = '${dqs_id}']`).toArray()) {
            let andere_id = corr.data('from') === dqs_id ? corr.data('to') : corr.data('from');
            let andereQ = this._getQuantity(this.cy.$id(andere_id));
            if (skip.includes(andereQ.id())) {
                continue; //stop de loop, dit gaat hem niet worden
            }
            //heeft die een waarde?
            if (this._vindChild(andereQ, 'derivative_value') || this._vindChild(andereQ, 'q_exo')) {
                return true; //externe waarde voor deze
            }
            //anders moet deze zelf een externe waarde hebben, maar niet via deze
            skip.push(Q_id);
            if (this._dBeginwaardeExtern(andereQ, skip)) {
                return true;
            }
        }
        return false; //niet gevonden
    }

    /**
     * Helper bij checkBeginwaarden,voor recursie. True als er een externe bron voor de beginwaarde van de quantity zelf is is
     * @param Q Quantity
     * @param {string[]} [skip] Ids van Q's waarvan we al kijken of de waarde extern is (loop-stop)
     * @private
     */
    _qBeginwaardeExtern(Q, skip) {
        skip = skip || [];
        let Q_id = Q.id();

        //checkBeginwaarden kijkt alleen naar Q's met een QS
        //wij ook naar Q's zonder QS, maar natuurlijk minder checks
        let QS = this._vindChild(Q, 'quantity_space');
        let QS_id = QS ? QS.id() : null;

        //gerichte correspondenties
        if (QS_id && this.cy.nodes(this._selectorForTypes(['correspondence_qs_directed', 'correspondence_qs_directed_reverse'], true)).is(`[to = '${QS_id}']`)) {
            return true;
        }
        //exacte uitkomst van een calculus
        if (this.cy.nodes(this._selectorForTypes(['q_ineq_eq'])).some(ineq =>
            ineq.data('to') === Q_id && this.elementService.isSubtypeOf(this.cy.$id(ineq.data('from')).data('type'), 'q_calc') ||
            ineq.data('from') === Q_id && this.elementService.isSubtypeOf(this.cy.$id(ineq.data('to')).data('type'), 'q_calc')
        )) {
            return true;
        }

        //bidirectionele correspondenties: hiervoor geldt dat de andere kant zelf ook weer een waarde moet
        //krijgen (en niet van deze)
        if (QS_id) {
            for (let corr of this.cy.nodes(this._selectorForTypes(['correspondence_qs_normal', 'correspondence_qs_reverse'])).filter(`[from = '${QS_id}'],[to = '${QS_id}']`).toArray()) {
                let andere_id = corr.data('from') === QS_id ? corr.data('to') : corr.data('from');
                let andereQ = this._getQuantity(this.cy.$id(andere_id));
                if (skip.includes(andereQ.id())) {
                    continue; //deze wordt al gecheckt via een loop
                }
                //heeft die een waarde?
                if (this._vindChild(andereQ, 'quantity_value') || this._vindChild(andereQ, 'quantity_allvalues')) {
                    return true; //externe waarde voor deze
                }
                //anders moet deze zelf een externe waarde hebben, maar niet via de correspondentie
                skip.push(Q_id);
                if (this._qBeginwaardeExtern(andereQ, skip)) {
                    return true;
                }
            }
        }

        //een specifieke waarde in de QS wordt gezet
        if (QS_id) {
            return this.cy.nodes(
                this._selectorForTypes(['quantity_space_element'], true)
            ).filter(`[qspace = "${QS_id}"]`).toArray().some(
                qsEl => this.qsElKrijgWaarde(qsEl, [], true));
        }

        return false;
    }

    /**
     * Geef true als het gegeven QSEl op de een of andere manier direct of extern een waarde krijgt
     * @param QSEl
     * @param {string[] | null} skip
     * @param skipDirect als true dan geen directe
     *
     * Er zit een waarde bij het element
     * Het komt binnen via een cor
     * Generate all values? (nee, want niet sluitend)
     */
    qsElKrijgWaarde(QSEl, skip, skipDirect) {
        skip = skip || [];
        //direct?
        if ((!skipDirect) && QSEl.outElements('[type = "quantity_value"]').nonempty()) {
            return true; //deze heeft een waarde
        }
        //gerichte cor of gewone cor met dit element aan de TO
        for (let cor of
            this.cy.nodes(
                this._selectorForTypes(['correspondence_qv'], true)
            ).filter(`[to = '${QSEl.id()}']`).toArray()) {
            //deze gaat dus naar dit element
            //we gaan dus door met de andere kant
            let andere = this.cy.$id(cor.data('from'));
            //skippen?
            if (skip.includes(andere.id())) {
                //al gedaan, cycle
                continue;
            }
            return this.qsElKrijgWaarde(andere, [QSEl.id(), ...skip]);
        }

        //en bij bidir de andere kant op (maar waarde komt dan niet van deze)
        for (let cor of
            this.cy.nodes(
                this._selectorForTypes(['correspondence_qv_normal'], true)
            ).filter(`[from = '${QSEl.id()}']`).toArray()) {
            //deze gaat dus naar dit element
            //we gaan dus door met de andere kant
            let andere = this.cy.$id(cor.data('to'));
            //skippen?
            if (skip.includes(andere.id())) {
                //al gedaan, cycle
                continue;
            }
            return this.qsElKrijgWaarde(andere, [QSEl.id(), ...skip]);
        }
    }

    ////interne helpers ////
    /**
     * Controleer of het element een child heeft van een bepaald type (of subtype daarvan). Recursief
     * @param element
     * @param {string} type
     * @param {boolean} direct=false Moet het een direct kind zijn?
     * @returns {boolean}
     * @private
     */
    _heeftChild(element, type, direct) {
        return !!this._vindChild(element, type, direct);
    }

    /**
     * Vind het eerste kindelement van een bepaald type (of subtype ervan). Recursief
     * @param element
     * @param {string} type
     * @param {boolean} [direct]=false Moet het een direct kind zijn?
     * @private
     */
    _vindChild(element, type, direct) {
        for (let childId of (element.data('childIds') || [])) {
            let child = this.cy.$id(childId);
            if (this.elementService.isSubtypeOf(child.data('type'), type)) {
                return child; //gevonden
            }
            //anders de recursie in, tenzij het een direct kind moet zijn
            if (!direct) {
                let subchild = this._vindChild(child, type);
                if (subchild) {
                    return subchild; //gevonden
                }
            }
        }
        //als we hier komen: niets gevonden
        return null;
    }

    _getQuantity(node) {
        if (node.data('type') === 'quantity') {
            return node;
        }
        let parentId = node.data('parentId');
        return parentId ? this._getQuantity(this.cy.$id(parentId)) : null;
    }

    /**
     * Maak een selector voor elementen van bepaalde types
     * @param {string[]} types
     * @param {boolean} [subtypes]
     * @param {boolean} [ookConditional]
     * @return {string}
     * @private
     */
    _selectorForTypes(types, subtypes, ookConditional) {
        let maptypes = [...types];
        if (subtypes) {
            for (let type of types) {
                maptypes.push(...(this.elementService.alleSubs(type)));
            }
        }
        // this.log.debug(maptypes.map(t => `[type="${t}"]${ookConditional ? '' : '[!condtag]'}`).join(","));
        return maptypes.map(t => `[type="${t}"]${ookConditional ? '' : '[!condtag]'}`).join(",");
    }

    /**
     * Push een nieuwe array van id's in een bepaalde feedbackcategorie, tenzij een gegeven element er al in zit
     * @param {string} feedbackkey
     * @param {string} checkElementId
     * @param {string[]} highlightArray
     * @private
     */
    _pushFeedbackAlsNiet(feedbackkey, checkElementId, highlightArray) {
        this.feedback[feedbackkey] = this.feedback[feedbackkey] || [];
        if (!this.feedback[feedbackkey].some(hla => hla.includes(checkElementId))) {
            this.feedback[feedbackkey].push(highlightArray);
        }
    }

}
