/* 
* ballon.directive.js
* 14-4-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Een popupballon bij een cy-node
* <ballon cy="scopevar" node-id="<id>"></ballon>
*/

dynalearn.directive('ballon', [
    '$timeout', 'sihwlog',
    function ($timeout, sihwlog) {
        let log = sihwlog.logLevel('debug');
        let id = 0;
        return {
            restrict: 'E', //element
            templateUrl: 'app/directives/ballon/ballon.html',
            scope: {
                cy: '=',
                nodeId: '=',
                showmode: '=', //scope.mode = 'edit' als het een editor is, dan is er geen sluitknop en alles
                readonly: '=', //als true, dan geen wegklik, voor replay
                wegKlik: '&wegKlik'

            },
            transclude: true,

            link: function (scope, $el, attrs) {
                log.debug(`Ballon`, scope.nodeId);
                let cy;
                let node;
                let timer = null;
                let sluitClicks = 0; //bij 2 dicht
                let myId = ++id;

                /**
                 * Initialiseer
                 */
                function init() {
                    log.debug(`Ballon ${myId}: ${scope.showmode}`);
                    cy = scope.cy; //even bewaren
                    scope.hidden = false;

                    scope.pos = {left: 0, top: 0}; //we plaatsen dit ding absolute, zie template
                    //positieclasses voor de tekstballon
                    //array met 2 args: de boven/onder class en de links/rechts class
                    scope.ballonpos = ['boven', 'rechts'];
                    scope.$watch("nodeId",initNode);
                    scope.$on('$destroy', _ => {
                        log.debug(`Ballon ${myId} destroy`);
                        ontkoppel();
                    });
                }

                //hang aan een specifieke node
                function initNode() {
                    //weg met eventuele oude node
                        ontkoppel();
                    node = cy.$id(scope.nodeId);
                    if (node) {
                        node.on('position', update);
                        node.on('remove', ontkoppel);
                        cy.on('viewport', update);
                        scope.hidden = false; //toon maar
                        update();
                    } else {
                        log.debug(`Ballon ${myId} - Node ${scope.nodeId} niet gevonden. Hide ballon.`);
                        scope.hidden = true;
                    }
                }

                function ontkoppel() {
                    //hide
                    log.debug(`Ballon ${myId}: ontkoppel`);
                    if (node) {
                        node.off('position', update);
                        node.off('remove', ontkoppel); //deze ook
                    }
                    if (cy) {
                        cy.off('viewport', update);
                    }
                    scope.hidden = true;
                    node = false; //release
                }

                /**
                 * Update de positie
                 */
                function update() {
                    if (scope.hidden) {
                        return; //geen aandacht aan besteden
                    }
                    if (!node) {
                        log.warn(`Ballon ${myId} Update zonder node`);
                        return;
                    } else {
                        log.debug(`Ballon ${myId} Update met node`);
                    }
                    //even verbergen en dan verplaatsen (via moving flag)
                    //altijd binnen scope apply ook:
                    $timeout(_ => {
                        scope.moving = true;
                    });
                    //inplannen, binnen scope en niet te vaak
                    if (timer) {
                        $timeout.cancel(timer);
                        timer = null;
                    }
                    timer = $timeout(_ => {
                        timer = null;
                        let bb = node.renderedBoundingBox();
                        let ttpos = Object.assign({
                            xf: 0.5, //standaard midden,
                            dx: -8,
                            yf: 0.5, //standaard midden,
                            dy: -8
                        }); //klaar voor iets met defaults en afwijkingen

                        //rechtsboven komt onze linksonder
                        scope.pos.left = Math.round(bb.x1 + ttpos.xf * bb.w + ttpos.dx);
                        scope.pos.top = Math.round(bb.y1 + ttpos.yf * bb.h + ttpos.dy);
                        //ballonpos
                        let cont = cy.container();
                        scope.ballonpos[0] = scope.pos.top < cont.clientHeight / 2 ? 'onder' : 'boven';
                        scope.ballonpos[1] = scope.pos.left < cont.clientWidth / 2 ? 'rechts' : 'links';
                        //    log.debug(scope.pos, scope.ballonpos);
                        scope.moving = false;
                    }, 100);
                }

                /**
                 * Klik op sluiten. 2x om te doen
                 */
                scope.clickX = function () {
                    if (sluitClicks === 0) {
                        sluitClicks = 1;
                        scope.sluitstap = true;
                        $timeout(_ => {
                            sluitClicks = 0;
                            scope.sluitstap = false;
                        }, 1500);
                    } else {
                        sluitClicks = 2;
                        scope.hidden = true; //weg
                        scope.wegKlik({nodeId: scope.nodeId});
                    }
                };

                /**
                 * Bepaald de scale() transform voor de ballon, gegeven de edit en de zoom
                 */
                scope.getScale = function() {
                    //we zoomen de helft minder dan de cy
                    if (scope.showmode === 'edit')
                    {
                        return 'none';
                    }
                    let scale = 1 - ((1 - cy.zoom()) / 2);
                    return `scale(${scale})`;
                }


                //init
                init();
            }
        };
    }
]);
